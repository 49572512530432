import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [navMenuActive, setNavMenuActive] = useState(false);
  const [servicesDropdownActive, setServicesDropdownActive] = useState(false);

  useEffect(() => {
    const handleNavItemClick = (event) => {
      const target = event.currentTarget;
      const isDropdownToggle = target.classList.contains("dropdown-toggle");

      if (isDropdownToggle) {
        event.preventDefault(); // Prevent default behavior for dropdown toggles

        // Toggle dropdown logic here if needed
        setServicesDropdownActive(!servicesDropdownActive);
      } else {
        const parentDropdown = target.closest(".dropdown-menu");

        if (parentDropdown) {
          // Toggle parent dropdown logic here if needed
        } else {
          setNavMenuActive(false); // Hide mobile navigation menu
        }
      }
    };

    const navLinks = document.querySelectorAll(".navbar-collapse a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavItemClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavItemClick);
      });
    };
  }, [servicesDropdownActive]);

  const handleHamburgerClick = () => {
    setNavMenuActive(!navMenuActive);
  };

  return (
    <header className="container">
      <nav
        className={`navbar navbar-expand-lg navbar-light TopnavBar-Section p-2`}
      >
        <div id="progress-barss"></div>
        <div className="container">
          <NavLink className="navbar-brand logo-section" to="/cst-home">
            <img
              className="main-logo"
              src="/images/navbar/chakravuhya-logo.svg"
              loading="eager"
              alt="Chakravuyha-Logo"
            />
          </NavLink>
          <form className="form-inline d-block d-lg-none">
            <NavLink className="btn login-txt" to={"/contact-us"} type="submit">
              Get Started
            </NavLink>
          </form>
          <div
            className={`hamburger-edit ${navMenuActive ? "active" : ""}`}
            onClick={handleHamburgerClick}
          >
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
          </div>
          <div
            className={`navbar-collapse navbarTop-colapse ${
              navMenuActive ? "active" : ""
            }`}
          >
            <ul className="navbar-nav mr-auto navbarTopUl-edit p-2">
              <li className="nav-item dropdown TopNavService-dropdown position-static">
                <div
                  className="nav-link navlink-mainlist dropdown-toggle"
                  // href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  Services
                </div>
                <div
                  className={`dropdown-menu megaService-dropdwn shadow ${
                    servicesDropdownActive ? "show" : ""
                  }`}
                >
                  <div className="mega-content px-md-4">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-6 pt-4 pr-4 pl-4">
                          <NavLink
                            className="nav-link navlink-mainlist"
                            to={"/services/web3-services"}
                          >
                            <img
                              className="dropdown-image mr-2"
                              src="/images/navbar/dropdown-img-1.png"
                              alt="no-data"
                            />
                            Web3 Services
                          </NavLink>
                          <div className="list-group">
                            <NavLink
                              to={
                                "/services/web3-services/native-payments-solutions"
                              }
                              className="list-group-item inside-navlist"
                            >
                              Native Payment Solutions
                            </NavLink>
                            <NavLink
                              to={"/services/web3-services/metaverse-products"}
                              className="list-group-item inside-navlist"
                            >
                              Metaverse Products
                            </NavLink>
                            <NavLink
                              to={"/services/web3-services/NFT-developement"}
                              className="list-group-item inside-navlist"
                            >
                              NFT Development
                            </NavLink>
                            <NavLink
                              to={"/services/web3-services/web3-gaming"}
                              className="list-group-item inside-navlist"
                            >
                              Web3 Gaming
                            </NavLink>
                            <NavLink
                              to={
                                "/services/web3-services/Blockchain-solutions"
                              }
                              className="list-group-item inside-navlist"
                            >
                              Blockchain Solutions
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-lg-6 pt-4 pr-4 pl-4">
                          <NavLink
                            className="nav-link navlink-mainlist"
                            to={"/services/web2-services"}
                          >
                            <img
                              className="dropdown-image mr-2"
                              src="/images/navbar/dropdown-img-2.png"
                              alt="no-data"
                            />
                            Web2 Services
                          </NavLink>
                          <div className="list-group">
                            <NavLink
                              to={
                                "/services/web2-services/web-applicaion-developement"
                              }
                              className="list-group-item inside-navlist"
                            >
                              Web Application Development
                            </NavLink>
                            <NavLink
                              to={"/services/web2-services/devops"}
                              className="list-group-item inside-navlist"
                            >
                              Devops
                            </NavLink>
                            <NavLink
                              to={
                                "/services/web2-services/mobile-app-developement"
                              }
                              className="list-group-item inside-navlist"
                            >
                              Mobile Apps
                            </NavLink>
                            <NavLink
                              to={"/services/web2-services/fullstack"}
                              className="list-group-item inside-navlist"
                            >
                              Full stack development
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-lg-6 pb-4 pr-4 pl-4">
                          <NavLink
                            className="nav-link navlink-mainlist"
                            to={"/services/hybrid-solutions"}
                          >
                            <img
                              className="dropdown-image mr-2"
                              src="/images/navbar/dropdown-img-3.png"
                              alt="no-data"
                            />
                            Hybrid Solutions
                          </NavLink>
                        </div>
                        <div className="col-lg-6 pb-4 pr-4 pl-4">
                          <NavLink
                            className="nav-link navlink-mainlist"
                            to={"/services/no-code-products"}
                          >
                            <img
                              className="dropdown-image mr-2"
                              src="/images/navbar/dropdown-img-4.png"
                              alt="no-data"
                            />
                            No-code Products
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link navlink-mainlist" to={"/about-us"}>
                  Company
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link navlink-mainlist" to={"/media"}>
                  Media
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink className="nav-link navlink-mainlist" to={"/blogs"}>
                  Blog
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className="nav-link navlink-mainlist"
                  to={"/contact-us"}
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <form className="form-inline d-none d-lg-block">
              <NavLink
                className="btn login-txt"
                type="button"
                to={"/contact-us"}
              >
                Get Started
              </NavLink>
            </form>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
