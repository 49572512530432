import React, { useEffect, useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import { NavLink } from "react-router-dom";

const Aboutus = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>About-Us</title>
      </Helmet>
      <div className="aboutus-main">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="./images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="./images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="./images/right.png" alt="no-data" />
        </div>

        <div className="aboutus-top" />
        <div className="aboutus-topinside">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="inside-aboutus">
                  <h3 className="aboutus-title">About Us</h3>
                  <p className="aboutus-para">
                    We are passionate about web development and think you should
                    be too Our goal at CST 3.0 is to consistently provide our
                    clients with high-quality web solutions that meet their
                    unique needs and exceed their expectations. We specialize in
                    Web3 development and are proud to be at the forefront of
                    this exciting and rapidly evolving field.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mission-vision">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="mission-data">
                  <p className="mission-title">
                    <img
                      className="mission-eye"
                      src="./images/aboutus/eye.png"
                      alt="no-data"
                    />
                    Mission Statement
                  </p>
                  <p className="mission-para">
                    Our mission is simple: to give our clients the ultimate web
                    development experience. We're passionate about delivering
                    top-notch solutions that cater to each client's unique
                    needs, and our goal is to exceed their expectations. From
                    custom application development to eye-catching Web3
                    solutions to handling complex projects, we're determined to
                    make a real impact.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mission-data">
                  <p className="mission-title">
                    <img
                      className="mission-eye"
                      src="./images/aboutus/eye1.png"
                      alt="no-data"
                    />
                    Vision
                  </p>
                  <p className="mission-para">
                    Our vision is to be the go-to web solution development
                    company for businesses of all sizes. We aim to be recognized
                    for our expertise, unwavering commitment to quality, and
                    love for the web. We're always on the lookout for new and
                    innovative ways to improve our services and stay ahead of
                    the game, with the ultimate goal of delivering the best
                    results for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="our-journey">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7">
                <div className="journey-content">
                  <h3 className="journey-data">Our Journey to the Now</h3>
                  <p className="journey-para">
                    We've been in the web development business since 2017, and
                    in that time, the team at CST 3.0 has had the privilege of
                    working with a diverse range of clients from all over the
                    world. Our journey has been filled with exciting challenges
                    and rewarding accomplishments. Most importantly, we've grown
                    and evolved alongside the web, and we're proud to be a part
                    of the web's continued development and growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wahtwe-offer">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-7">
                <div className="wahtwe-offer-content">
                  <h3 className="wahtwe-offer-data">What We Offer</h3>
                  <p className="wahtwe-offer-para">
                    We offer high-quality, custom web development solutions that
                    meet your unique needs and exceed your expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="offer-data">
            <div className="container">
              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-1.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        Web3 Services
                      </h5>
                      <p className="card-text service-subtitle">
                        Conceptualize and create interactive web3 solutions
                        using advanced tech like blockchain, AI, NFTs, IoT and
                        cryptography. With our experience and your drive, let’s
                        build the pioneering foundations of the internet's next
                        iteration.
                      </p>
                      <NavLink
                        to={"/services/web3-services"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-2.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        Web2 Services
                      </h5>
                      <p className="card-text service-subtitle">
                        We provide custom solutions that helps you stay ahead of
                        the competition by using innovative, purpose-driven
                        emerging technologies. We understand what it takes to
                        craft an engaging user experience, and have a proven
                        track.
                      </p>
                      <NavLink
                        to={"/services/web2-services"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-3.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        Hybrid Solutions
                      </h5>
                      <p className="card-text service-subtitle">
                        Our hybrid solutions are designed to provide your users
                        with the best of both worlds – the comfort of
                        traditional web browsing combined with the latest
                        technology advancements in blockchain and cryptography.
                      </p>
                      <NavLink
                        to={"/services/hybrid-solutions"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-4.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        No-Code Products
                      </h5>
                      <p className="card-text service-subtitle">
                        Technology should facilitate and ease the creation of
                        products, not be a barrier to entry. Join the No-Code
                        revolution now and build – applications, websites, and
                        many digital product MVPs with us in quick turnarounds.
                      </p>
                      <NavLink
                        to={"/services/no-code-products"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-5.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        The CST 3.0 Advantage
                      </h5>
                      <p className="card-text service-subtitle">
                        Conceptualize and create interactive web3 solutions
                        using advanced tech like blockchain, AI, NFTs, IoT and
                        cryptography. With our experience and your drive, let’s
                        build the pioneering foundations of the internet's next
                        iteration.
                      </p>
                      <NavLink
                        to={"/contact-us"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div ref={sectionRef} className="vyuha-team">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="vyuha-content">
                  <h3 className="vyuha-title">The CST 3.0 Team</h3>
                  <p className="vyuha-para">
                    CST 3.0 is comprised of a talented, passionate, and
                    experienced team of developers, designers, and project
                    managers who are committed to delivering only the best web
                    solutions to our clients. Our team members come from a
                    variety of backgrounds and bring a wealth of knowledge and
                    expertise to the table.&nbsp;
                  </p>
                  <p className="vyuha-para">
                    Whether it's developing custom applications, designing
                    beautiful and user-friendly websites, or managing complex
                    Web3 projects, our team is up to the task. We work together
                    seamlessly to ensure that every project we take on is a
                    success.
                  </p>
                </div>
                {/* <div className="meet-team">
                  <h3 className="team-title">Meet our management team:</h3>
                  <div className="row justify-content-center">
                    <div className="col-md-4">
                      <div className="team-inside">
                        <img
                          className="team-image"
                          src="./images/aboutus/team-images.png"
                          alt="no-data"
                        />
                        <p className="team-title">FIrstname Lastname</p>
                        <p className="team-subtitle">CEO</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="team-inside">
                        <img
                          className="team-image"
                          src="./images/aboutus/team-images.png"
                          alt="no-data"
                        />
                        <p className="team-title">FIrstname Lastname</p>
                        <p className="team-subtitle">CEO</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="team-inside">
                        <img
                          className="team-image"
                          src="./images/aboutus/team-images.png"
                          alt="no-data"
                        />
                        <p className="team-title">FIrstname Lastname</p>
                        <p className="team-subtitle">CEO</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="team-inside">
                        <img
                          className="team-image"
                          src="./images/aboutus/team-images.png"
                          alt="no-data"
                        />
                        <p className="team-title">FIrstname Lastname</p>
                        <p className="team-subtitle">CEO</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="team-inside">
                        <img
                          className="team-image"
                          src="./images/aboutus/team-images.png"
                          alt="no-data"
                        />
                        <p className="team-title">FIrstname Lastname</p>
                        <p className="team-subtitle">CEO</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="vyuha-content vyuha-content1">
                  <h3 className="vyuha-title">Value Proposition</h3>
                  <p className="vyuha-para">
                    Our value proposition is simple: We deliver high-quality web
                    solutions that meet our clients' unique needs and exceed
                    their expectations.&nbsp;
                  </p>
                  <p className="vyuha-para">
                    We believe every website or web application should reflect
                    the business it represents, and we work tirelessly to ensure
                    that our clients' online presence is a true reflection of
                    their brand.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="start-making-content">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="making-content">
                  <h3 className="making-title">Why CST 3.0?</h3>
                  <p className="making-para">
                    What sets us apart is our people-first approach to web
                    development. We take the time to get to know our clients and
                    understand their needs, and we work closely with them to
                    develop solutions that meet their unique requirements.&nbsp;
                  </p>
                  <p className="making-para">
                    Our team of experts is dedicated to delivering the best
                    possible results, and we use the latest technologies and
                    best practices to ensure that every project we take on is a
                    success. Whether you're looking to build a blockchain
                    solution, develop a digital asset or a custom web
                    application, or transition from Web2 to Web3, we're here to
                    help.&nbsp; With our expertise, commitment to quality, and
                    passion for the web, we're confident that we can deliver the
                    results you seek. Get in touch with us today to find out
                    more!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="testimonials-content">
          <div className="container">
            <div className="testimonials-top">
              <p className="testimonials-title">Testimonials</p>
              <p className="testimonials-subtitle">
                Our clients are our biggest advocates, and we're proud to have
                received so many positive testimonials from satisfied customers.
                From small businesses to large corporations, our clients have
                praised our dedication, expertise, and commitment to delivering
                high-quality web solutions. We look forward to having you as one
                of our advocates. In the meantime, here are some comments from
                our clients:
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <Swiper
                  direction={"horizontal"}
                  centeredSlides={false}
                  navigation={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      // spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                  grabCursor={true}
                  freeMode={true}
                  spaceBetween={50}
                  // slidesPerView={6}
                  scrollbar={{ draggable: true }}
                >
                  <SwiperSlide>
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image"
                        src="./images/landing/testi-image-1.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-2.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-3.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image"
                        src="./images/landing/testi-image-1.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-2.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-3.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div> */}
        <div className="reactout-content">
          <div className="container">
            <p className="reachout-top">Get in Touch</p>
            <p className="reachout-para">
              We at CST 3.0 are always happy to hear from prospective
              clients!&nbsp; Whether you have a question about our services or
              you're ready to start that new project, we'd love to hear from
              you. Simply fill out the form on our Get in Touch page, and one of
              our team members will walk you through our services and answer
              those questions in no time.
            </p>
            <NavLink className="getintouch-btn" to={"/contact-us"}>
              Get in touch
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
