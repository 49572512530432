import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Nativepayments = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>Native Payments</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">CST 3.0’s Crypto Wallets</h1>
                <p className="mainsubtxt">
                  Launch a crypto wallet for your brand with a simple &amp;
                  powerful UI &amp; UX.&nbsp; With our super secure crypto
                  wallet solution, create a multi-chain and multi-currency
                  custom crypto wallet that comes with simplified UX, advanced
                  features, and powerful security.
                </p>
                <div className="text-center">
                  <NavLink to={"/contact-us"} className="clickbtn">
                    Get a Demo
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h2 className="nativepayment2head">
                  CST 3.0 Crypto Wallet Functionality
                </h2>
                <p className="nativepayment2txt">
                  One crypto wallet for all your Web3 needs.&nbsp; Our wallet is
                  tailor-made to meet the expectations of Web3 and crypto users.
                  Receive Payments, Give out Rewards, and integrate them into
                  your Marketplace instantly.&nbsp; With our wallet, your users
                  can easily track their portfolios, be assured of
                  industry-grade security, and enjoy unlimited compatibility
                  with all the popular digital currencies.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection3">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="nativepayment3head">
                  One Wallet. Multiple Use Cases.
                </h2>
                <p className="nativepayment3txt">
                  Biometric Authentication | Ledger Support | Hardware Wallet
                  Compatibility,&nbsp; Web3 Integrations | Asset Management |
                  Multiple Network Support
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Why Is A Crypto Wallet Crucial To Your Business?
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box">
                  <h6 className="nativepayment4boxtxt">Acquire More Clients</h6>
                  <p className="nativepayment4boxtxt2">
                    Attract crypto users and expand your reach by launching an
                    eWallet tailored to your brand.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box">
                  <h6 className="nativepayment4boxtxt">
                    Maximize Client Engagement
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Strengthen customer relationships and increase loyalty by
                    offering cryptocurrency-based gamified rewards.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box">
                  <h6 className="nativepayment4boxtxt">
                    Make The Most Out of Your Client Base
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Leverage your customer base and offer DeFi functionalities
                    by providing crypto loans &amp; credit capabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection5">
          <div className="container">
            <h2 className="nativepayment5head">Platforms We Support</h2>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <img
                  className="nativepayment5im"
                  src="/images/innerpages/NativePaymentSolutions/img1.png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-3">
                <img
                  className="nativepayment5im"
                  src="/images/innerpages/NativePaymentSolutions/img2.png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-3">
                <img
                  className="nativepayment5im"
                  src="/images/innerpages/NativePaymentSolutions/img3.png"
                  alt="no-data"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection5">
          <div className="container">
            <h2 className="nativepayment5head">Supported Blockchains</h2>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <img
                  className="nativepayment5im"
                  src="/images/innerpages/NativePaymentSolutions/img6.png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-3">
                <img
                  className="nativepayment5im"
                  src="/images/innerpages/NativePaymentSolutions/img7.png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-3">
                <img
                  className="nativepayment5im"
                  src="/images/innerpages/NativePaymentSolutions/img8.png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-3">
                <img
                  className="nativepayment5im"
                  src="/images/innerpages/NativePaymentSolutions/img9.png"
                  alt="no-data"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="NativePaymentsection6">
          <div className="container">
            <h2 className="nativepayment6head">Our Recent Work</h2>
            <div className="row">
              <div className="col-md-5">
                <div className="nativepayment6main">
                  <img
                    className="nativepayment6im"
                    src="/images/innerpages/NativePaymentSolutions/image-12.png"
                    alt="no-data"
                  />
                  <p className="nativepayment6txt">
                    We and our clients love everything about our service and
                    thanks to Diffco we have super cool website for our clients
                    and easy to use order/client management admin part for us.
                  </p>
                  <div className="media">
                    <img
                      src="/images/innerpages/NativePaymentSolutions/image4.png"
                      alt="no-data"
                      className="mr-2"
                    />
                    <div className="media-body">
                      <h5 className="mt-0 nativepayment6cli">
                        Ruslana Golunova
                      </h5>
                      <p className="nativepayment6des">Co-founder, Bitium</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="nativepayment6im2">
                  <img src="/images/innerpages/NativePaymentSolutions/image21.png"   alt="no-data" />
                </div>
                <NavLink
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  href="https://bitium.io/"
                  TO="_blank"
                >
                  <p className="checkitout">Check it out</p>
                </NavLink>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Nativepayments;
