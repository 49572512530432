import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet";

// lottie files
import money from "./../../../src/animations/money.json";
import metaverse from "./../../../src/animations/metaverse.json";
import nft from "./../../../src/animations/NFT.json";
import games from "./../../../src/animations/Game.json";
import blockchain from "./../../../src/animations/Blockchain.json";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
const Web3 = () => {
  const [counts, setCounts] = useState({ count1: 0, count2: 0,count3: 0,cpunt4: 0 });
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    let timers = {};

    function counter(id, start, end, duration) {
      let current = start;
      const range = end - start;
      const increment = end > start ? 1 : -1;
      const step = Math.abs(Math.floor(duration / range));
      
      timers[id] = setInterval(() => {
        current += increment;
        setCounts(prevCounts => ({
          ...prevCounts,
          [id]: current
        }));
        if (current === end) {
          clearInterval(timers[id]);
        }
      }, step);
    }

    counter("count1", 0, 20, 1000);
    counter("count2", 0, 10, 1000);
    counter("count3", 0, 8, 1000);
    counter("count4", 0, 7, 1000);

    return () => {
      Object.values(timers).forEach(timer => clearInterval(timer));
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Web3 Services</title>
      </Helmet>
      <div className="web3-allcontent">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        <div className="leftblur-image leftblur-image2">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image rightblur-image2">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* ===  web-3 solutions Start  === */}
        <div className="web3-solutions">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="web3-solutions-left">
                  <img
                    className="mask-img"
                    src="/images/web3/mask-service.png"
                    alt="Web3-Solutions"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="web3-solutions-right">
                  <p className="web3-solutions-title">Web3 Solutions</p>
                  <p className="web3-solutions-content">
                    We specialize in designing and building interactive
                    solutions with blockchain, AI, NFTs, IoT, and cryptography
                    technologies.
                  </p>
                  <p className="web3-solutions-content">
                    Our services span various industries, from gaming to real
                    estate and fintech.
                  </p>
                  <NavLink
                    type="button"
                    to={"/contact-us"}
                    className="get-started"
                  >
                    Get started
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  web-3 solutions End  === */}
        {/* ===  AI solutions Start  === */}
        <div className="ai-solutions">
          <div className="container">
            <div className="ai-solutions-in">
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title" id="count4">{counts.count4}</li>
                <li className="ai-solutions-inside">AI Solutions</li>
              </ul>
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title">
                <span id="count1" className="ai-solutions-inside-title">{counts.count1}</span>
                
                </li>
                <li className="ai-solutions-inside">Total Projects Delivered</li>
              </ul>
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title" id="count2">{counts.count2}</li>
                <li className="ai-solutions-inside">Full Stack Developers</li>
              </ul>
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title" id="count3">{counts.count3}</li>
                <li className="ai-solutions-inside">
                  Years of Market Presence
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* ===  AI solutions end  === */}
        {/* ===  web-3 dreams start  === */}
        <div className="web3-dreams">
          <div className="container">
            <div className="web3-dreams-inside">
              <p className="web-dreams-title">
                Make Your Web3 Dreams A Reality With CST 3.0
              </p>
              <p className="web-dreams-content">
                Here’s Everything We Build In The World of Web3
              </p>
            </div>
          </div>
        </div>
        {/* ===  web-3 dreams end  === */}
        {/* ===  native payment start  === */}
        <div className="native-payment">
          <div className="container">
            <div className="native-payment-inside">
              <div className="row">
                <div className="col-md-6">
                  <Lottie
                    className="money-images"
                    animationData={money}
                    loop={true}
                  />
                </div>
                <div className="col-md-6">
                  <div className="native-payment-right">
                    <p className="native-payment-title">
                      Native Payment Solutions
                    </p>
                    <p className="native-payment-content">
                      Our advanced digital wallets offer a comprehensive range
                      of utilities to manage web3 platforms.
                    </p>
                    <p className="native-payment-content">
                      Store and manage your platform’s native currency or use it
                      as a key to access web3 dApps across various blockchains.
                    </p>
                    <p className="native-payment-content">
                      Make the most out of the web3 world now with our powerful
                      wallets!
                    </p>
                    <NavLink
                      type="button"
                      className="learn-more"
                      to={"/services/web3-services/native-payments-solutions"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  native payment end  === */}
        {/* ===  metaverse products start  === */}
        <div className="metaverse-products">
          <div className="container">
            <div className="metaverse-products-inside">
              <div className="row">
                <div className="col-md-6">
                  <div className="metaverse-products-left">
                    <p className="metaverse-products-title">
                      Metaverse Products
                    </p>
                    <p className="metaverse-products-content">
                      Discuss and develop futuristic Metaverse products with us
                      that create tangible business value.
                    </p>
                    <p className="metaverse-products-content">
                      Leverage our experience in augmented reality, virtual
                      reality, and 3D reconstruction tech to create powerful
                      components such as 3D immersive spaces, NFT marketplaces,
                      customized metaverse, and more!
                    </p>
                    <p className="metaverse-products-content">
                      Unlock the potential of metaverse now!
                    </p>
                    <NavLink
                      type="button"
                      className="learn-more-products"
                      to={"/services/web3-services/metaverse-products"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="metaverse-products-right">
                    <Lottie
                      className="customers-img"
                      animationData={metaverse}
                      loop={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  metaverse products end  === */}
        {/* ===  nft development start  === */}
        <div className="nft-development">
          <div className="container">
            <div className="nft-development-inside">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-md-6 ">
                  <div className="nft-development-left">
                    <Lottie
                      className="cards-img"
                      animationData={nft}
                      loop={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="nft-development-right">
                    <p className="nft-development-title">NFT Development</p>
                    <p className="nft-development-content">
                      Construct interactive NFT marketplaces utilizing our
                      tailored solutions.
                    </p>
                    <p className="nft-development-content">
                      We provide ready-to-utilize products that can be quickly
                      integrated into your web3 projects, delivering a
                      three-dimensional realistic atmosphere for transactions
                      and real-time auctions.
                    </p>
                    <p className="nft-development-content">
                      Reduce time-to-sale and generate more value with our NFT
                      products right away.
                    </p>
                    <NavLink
                      type="button"
                      className="learn-more-nft"
                      to={"/services/web3-services/NFT-developement"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  nft development end  === */}
        {/* ===  web3 gaming start  === */}
        <div className="web-gaming">
          <div className="container">
            <div className="web-gaming-inside">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="web-gaming-left">
                    <p className="web-gaming-title">Web3 Gaming</p>
                    <p className="web-gaming-content">
                      Our developers, proficient in utilizing advanced game
                      development tools such as Unreal Engine 5 and Unity,
                      specialize in creating modern-age games, including the
                      wildly popular "play-to-earn" and NFT-based multiplayer
                      titles.
                    </p>
                    <p className="web-gaming-content">
                      {" "}
                      We continuously research trends throughout the gaming
                      industry to keep up with ever-changing development
                      requirements.
                    </p>{" "}
                    <NavLink
                      type="button"
                      className="learn-more-gaming"
                      to={"/services/web3-services/web3-gaming"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="web-gaming-right">
                    <Lottie
                      className="games-img"
                      animationData={games}
                      loop={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  web3 gaming end  === */}
        {/* ===  blockchain solutions start  === */}
        <div className="blockchain-solutions">
          <div className="container">
            <div className="blockchain-solutions-inside">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-md-6">
                  <div className="blockchain-solutions-left">
                    <Lottie
                      className="blockchain-image"
                      animationData={blockchain}
                      loop={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blockchain-solutions-right">
                    <p className="blockchain-solutions-title">
                      Blockchain Solutions
                    </p>
                    <p className="blockchain-solutions-content">
                      Harness the knowledge and specialized tech background of
                      CST 3.0’s most skilled minds to resolve your toughest
                      technology challenges and acquire the tools needed to
                      triumph in the blockchain field.
                    </p>
                    <p className="blockchain-solutions-content">
                      Our comprehensive blockchain consulting services enable
                      businesses to attain enduring improvements in performence
                      and develop more lasting innovations within the tech
                      realm.
                    </p>
                    <NavLink
                      type="button"
                      className="learn-more-blockchain"
                      to={"/services/web3-services/Blockchain-solutions"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===  blockchain solutions end  === */}
        {/* ===  product development start  === */}
        <div className="product-development">
          <div className="container">
            <div className="inside-product-development">
              <p className="product-development-title">
                Our Web3 Product Development Process
              </p>

              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
                dir="ltr"
              >
                <SwiperSlide>
                  <div className="project-mapping">
                    <p className="project-mapping-title">Project Mapping</p>
                    <p className="project-mapping-content">
                      We employ a comprehensive project planning procedure to
                      concisely summarize your project objectives, priorities,
                      and potential impediments.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="project-mapping">
                    <p className="project-mapping-title">Technical Design</p>
                    <p className="project-mapping-content">
                      Our technical design is focused on gathering project
                      requirements. With the acquired data, we design the
                      prototype for your Web3 solution and perform its user
                      testing.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="project-mapping">
                    <p className="project-mapping-title">Development</p>
                    <p className="project-mapping-content">
                      At this stage, our developers perform coding and
                      programming for your Web3 project based on the approved
                      design, preparing the product for the alpha, beta and
                      release phase.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="project-mapping">
                    <p className="project-mapping-title">
                      Testing and Optimization
                    </p>
                    <p className="project-mapping-content">
                      Our team thoroughly tests various components of your
                      product, highlighting defects in the code and errors. We
                      then remove the vulnerabilities and perform optimization.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        {/* ===  product development end  === */}
        {/* ===  why choose vuyha start  === */}
        <div className="choose-vuyha">
          <div className="container">
            <div className="choose-vuyha-inside">
              <p className="choose-vuyha-title">
                Why Choose CST 3.0 For Web3 Development
              </p>
              {/* <p className="choose-vuyha-content">
                Lorem ipsum dolor sit amet consectetur. Tristique scelerisque
                lobortis tellus in habitasse.
              </p> */}
            </div>
            <div className="choose-vuyha-inside-cards">
              <div className="internally-trained">
                <p className="internally-trained-title">
                  Internally Trained Developers
                </p>
                <p className="internally-trained-content">
                  With a team of 250+ internally trained developers, we offer
                  end-to-end web3 development services for DeFi, metaverse,
                  gaming real-estate, e-commerce, supply chain, and more.
                </p>
              </div>
              <div className="internally-trained">
                <p className="internally-trained-title">
                  Web3 Domain Expertise
                </p>
                <p className="internally-trained-content">
                  Our developers are skilled in the web3 domain. From performing
                  wallet integration to building a full-fledged web3 solution,
                  we undertake and excel in diverse projects.
                </p>
              </div>
              <div className="internally-trained">
                <p className="internally-trained-title">
                  Wide Project Experience
                </p>
                <p className="internally-trained-content">
                  We have experience working on a wide range of web3 projects,
                  from gaming and NFTs to the metaverse. Furthermore, our team
                  is always ready to undertake innovative and challenging
                  projects.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ===  why choose vuyha end  === */}
        {/* ===  listen & understand start  === */}
        <div className="listen-understand">
          <div className="container">
            <div className="listen-understand-inside">
              <p className="listen-understand-top">
                Building A Web3 Product With Us Is This Simple
              </p>
              <p className="listen-understand-title">
                We Listen &amp; Understand
              </p>
              <p className="listen-understand-content">
              We dedicate our time to thoroughly understanding your business objectives. Through multiple consultations and brainstorming sessions, we strategize effectively to meet your needs. 
              </p>
              <NavLink
                type="button"
                to={"/contact-us"}
                className="start-conversation"
              >
                Start a conversation
              </NavLink>
            </div>
          </div>
        </div>
        {/* ===  listen & understand end  === */}
      </div>
    </>
  );
};

export default Web3;
