import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
// import { NavLink } from "react-router-dom";

const Webapplications = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>Web Development</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">
                  Web Application Development Company
                </h1>
                <p className="mainsubtxt">
                  Our web application development team creates web applications
                  designed to meet specific business requirements. Leveraging
                  our hands-on experience in the latest technologies like
                  Postgre SQL, MongoDB, Angular, Node.js, React and Python, we
                  simplify complex business workflows with adaptive web apps.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Enterprise-grade web apps to enrich business capabilities
            </h2>
            <p className="ourblockchain">
              We implement agile web application development methods to achieve
              optimum adaptability, scalability, and functionality. Our team
              ensures to meet business challenges and provide compelling
              solutions with reliable, fast and efficient custom web
              applications.
            </p>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Our Web Application Development Services
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">
                    SaaS Application Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our SaaS developers build secure and multi-tenant SaaS web
                    solutions that allow you to expand the scope of your web app
                    as the business grows.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">
                    Front-end Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our developers can build single-page web apps with the
                    reusable and component-driven architecture of React.js,
                    Angular.js, Vue.js.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">Back-end Development</h6>
                  <p className="nativepayment4boxtxt2">
                    Our backend web developers use microservices architecture to
                    achieve scalability, services like AWS and Azure to host the
                    backend and SQL and NoSQL databases to store and fetch data
                    reliably.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">Progressive Web Apps</h6>
                  <p className="nativepayment4boxtxt2">
                    We develop mobile-first and cross-platform web apps with
                    responsive UX and modern web APIs to decrease bounce rates
                    and increase customer engagement.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">
                    Digital Transformation
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We adapt to the changing business environments with seamless
                    cross-platform migration of existing applications to new
                    technologies, ensuring zero data leakages.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">
                    Custom Software Development Services
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We are specialized in building custom web apps that ensure
                    compatibility, scalability and usability, ensuring
                    consistent user experiences across different devices and
                    browsers.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">
                    Mobile Responsive Web Apps
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We utilize the latest mobile technologies to develop
                    customer-centric mobile-responsive apps that are robust and
                    scalable. Our aim is to provide an engaging user experience
                    to clients and their customers.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">AI-based Web Apps</h6>
                  <p className="nativepayment4boxtxt2">
                    We develop AI-based web apps to build recommendation
                    engines, implement image recognition and enable intelligent
                    automation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="VyuhaAdvantage-section">
          <h2 className="VyuhaAdvantagehead VyuhaAdvantagehead2">
            Why are we the top Web Application Development Company?
          </h2>

          <Swiper
            direction={"horizontal"}
            centeredSlides={false}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
            grabCursor={true}
            freeMode={true}
            spaceBetween={50}
            // slidesPerView={6}
            scrollbar={{ draggable: true }}
            dir="ltr"
          >
            <SwiperSlide>
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Reliable Software</h6>
                <p className="Advantageslidesub">
                  Our web application development services involve rigorous
                  testing and adherence to global standards for building smooth,
                  efficient, and reliable custom applications.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Agile Practices</h6>
                <p className="Advantageslidesub">
                  Our multi-faceted and cross-functional teams deliver risk-free
                  and sustainable solutions by implementing agile web-based
                  application development practices.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Cutting Edge Technology</h6>
                <p className="Advantageslidesub">
                  With the latest technology stack and experienced web
                  developers, we help our clients achieve faster time-to-market
                  and adapt to the ever-evolving business environment.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Best Customer Experience</h6>
                <p className="Advantageslidesub">
                  We enhance customer engagements to consolidate brand image
                  with applications that are sleek, progressive and support
                  advanced features such as chat, location sharing and more.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head nativepayment4head2">
              Technologies our Custom Software Developers work on
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    Artificial Intelligence
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We implement AI in web apps to make search faster, enable
                    relevant customer interaction and provide a personalized
                    user experience.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    Web Application Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We use machine learning to identify patterns based on the
                    data, understand customer behavior and boost customer
                    engagement.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    Voice-Enabled Solution
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We broaden horizons, achieve better efficiency, and harness
                    the power of smart contracts and decentralized storage by
                    building blockchain-based web apps.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">Blockchain</h6>
                  <p className="nativepayment4boxtxt2">
                    We broaden horizons, achieve better efficiency, and harness
                    the power of smart contracts and decentralized storage by
                    building blockchain-based web apps.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">Internet of Things</h6>
                  <p className="nativepayment4boxtxt2">
                    We use IoT to transform user interactions and front-end
                    interfaces that allow users to interact with sensors,
                    cameras and other devices on the internet.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    Robotic Process Automation
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We use RPA tools to add intelligent automation to web
                    applications that enable users to convert tedious tasks into
                    UI automation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head nativepayment4head2">
              Our Development Process
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/profile-2user.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Planning and consulting
                  </h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/designtools.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Visual and Technical Design
                  </h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/code2.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Development &amp; Testing
                  </h6>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/code.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Deployment
                  </h6>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box9">
                  <img
                    className="devprocess"
                    src="/images/innerpages/WebApplicationDevelopment/chart-success.png"
                    alt="no-data"
                  />
                  <h6 className="nativepayment4boxtxt nativepayment4boxtxt3">
                    Upgrades
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Our Web Application Platforms
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box10">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim4"
                        src="/images/innerpages/WebApplicationDevelopment/image(33).png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Microsoft Azure
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We create and deploy enterprise-level web apps on Microsoft
                    Azure that scale with the growth of your business.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box10">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim4"
                        src="/images/innerpages/WebApplicationDevelopment/image(34).png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Google Cloud
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Reaping the benefits of Google’s Pay as You-Go feature, we
                    deploy highly interoperable web applications on the cloud
                    for zero up-front charges.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box10">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim4"
                        src="/images/innerpages/WebApplicationDevelopment/image(32).png"
                        alt="no-data"
                      />
                    </span>{" "}
                    AWS
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We build and deploy web apps on AWS that offers on-demand
                    access to scalable app servers, storage, database servers
                    and content delivery.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box10">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim4"
                        src="/images/innerpages/WebApplicationDevelopment/image(31).png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Alibaba
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We deploy web apps on Alibaba Web App Service that allows
                    you to deploy, scale and monitor apps quickly and flexibly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Webapplications;
