import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
const Blockchainsolutions = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>Blockchain Solutions</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">Blockchain Consulting Services</h1>
                <p className="mainsubtxt">
                  Leverage the expertise and deep tech background of the best
                  minds at CST 3.0, to solve your hardest technology challenges
                  and acquire the capabilities needed to excel in the blockchain
                  space.
                </p>
                <p className="mainsubtxt">
                  Our comprehensive blockchain consulting services empower
                  organizations to achieve lasting gains in performance and
                  innovate more sustainably in the tech sphere. Working
                  together, we will see big ideas turn into working realities
                  Whether you are looking to make a foray into the blockchain
                  space, select a protocol for development, launch a new
                  decentralized product/service or just strengthen your presence
                  in the market, partner with us for guidance.
                </p>
              
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Our Blockchain Consulting Services
            </h2>
            <p className="ourblockchain">
              Our blockchain consulting services focused on areas like DeFi,
              NFTs, metaverse, decentralized gaming and web3 will help your
              business expand its core competencies and implement technologies
              better.
            </p>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box3">
                  <h6 className="nativepayment4boxtxt">
                    Blockchain Consultancy
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We examine your existing solution, identify the need for a
                    blockchain solution specific to your business use case and
                    analyze how blockchain will add value to your business. We
                    suggest the right technology and the possible solutions for
                    the proposed project.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box3">
                  <h6 className="nativepayment4boxtxt">Quick PoC</h6>
                  <p className="nativepayment4boxtxt2">
                    Our team creates a Proof of Concept (PoC) to demonstrate the
                    practical potential of your blockchain project in 4 weeks.
                    The PoC helps clients understand how their blockchain
                    ecosystem will work.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box3">
                  <h6 className="nativepayment4boxtxt">
                    Blockchain Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Once you understand the blockchain feasibility for your use
                    case, you can move your project to development. As a leading
                    blockchain development company, we develop and deliver
                    scalable blockchain solutions to innovate your business
                    operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="VyuhaAdvantage-section">
          <h2 className="VyuhaAdvantagehead">
            Our Blockchain Consulting Process
          </h2>

          <Swiper
            direction={"horizontal"}
            centeredSlides={false}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
            loop={true}
            slidesPerView={1}
            grabCursor={true}
            freeMode={true}
            spaceBetween={50}
            // slidesPerView={6}
            scrollbar={{ draggable: true }}
            dir="ltr"
          >
            <SwiperSlide>
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Ideation</h6>
                <p className="Advantageslidesub">
                  We discuss the feasibility of your blockchain project with our
                  blockchain developers and define the business goals and
                  workflow for your system.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Assessment</h6>
                <p className="Advantageslidesub">
                  We analyze your existing solution and discover if it can be
                  migrated to the blockchain. Our team assesses entire business
                  procedures and identifies where blockchain can be applied to
                  your use case.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">
                  Technical Component Definition
                </h6>
                <p className="Advantageslidesub">
                  Our blockchain consulting team identifies the right blockchain
                  platform based on your business requirements and the type of
                  blockchain.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">PoC</h6>
                <p className="Advantageslidesub">
                  We create a framework and prototype to identify the use cases
                  for the business with minimum viable features. The demo is
                  used for the viability of a real solution.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">Blockchains We Work On</h2>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim"
                        src="/images/innerpages/BlockchainSolutions/image3323.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Ethereum
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    A decentralized public platform that runs dApps
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1366.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Stellar
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Stellar is an open platform for building financial products
                    that connect people everywhere.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1367.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Hyperledger
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    An umbrella project of open-source blockchains to build
                    enterprise-grade blockchain apps
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1368.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Corda
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Corda is an open source blockchain platform for businesses
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim3"
                        src="/images/innerpages/BlockchainSolutions/image1369.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Substrate
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Substrate is a blockchain development framework. We build
                    relay chains, parachains, cross chain bridges and dapps
                    using Substrate, ink! and RUST.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1370.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Avalanche
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Avalanche protocol enables the developers to build custom
                    blockchain networks spanning private and permissionless
                    deployments
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1371.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Polkadot
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Polkadot is an open-source web3 blockchain network for
                    enabling interoperability and interconnectivity.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1372.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    NEAR Protocol
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Near is a smart-contract capable layer 1 blockchain designed
                    to support next-generation dApp development.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1373.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Hedera
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Fair, Fast and most secured acrylic graph DLT solution for
                    building EVM compatible, high speed blockchain solutions.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="nativepayment4box nativepayment4box7">
                  <h6 className="nativepayment4boxtxt">
                    <span>
                      <img
                        className="nativepayim nativepayim2"
                        src="/images/innerpages/BlockchainSolutions/image1374.png"
                        alt="no-data"
                      />
                    </span>{" "}
                    Tezos
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    An Open-Source Platform for Decentralized Assets and
                    Applications
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="Subscribenewsletter-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h2 className="SubscribenewsletterHead">
                  Subscribe to our newsletter
                </h2>
                <p className="Subscribenewslettertxt">
                  Create futuristic, next-gen products with CST 3.0 and Stay
                  up-to-date with the latest technology trends and innovations!
                  Subscribe now for exclusive insights and updates from our
                  experts!
                </p>
                <form className="subscribe-form">
                  <div className="row justify-content-center">
                    <div className=" col-md-6">
                      <div className>
                        <input
                          type="email"
                          className="form-control email-data"
                          id="email"
                          placeholder="Your email address"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary subscribe-btn"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Blockchainsolutions;
