import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Devops = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>DevOps</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">DevOps</h1>
                <p className="mainsubtxt">
                  Hire our DevOps engineers to scale your business quickly and
                  get DevOps services, ranging from development, automation,
                  plugin integration to API development. Our DevOps engineers
                  work as your extended team, therefore, you get a complete
                  control over the team.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head nativepayment4head2">
              Automating your software delivery process with DevOps
            </h2>
            <p className="ourblockchain">
              Using a wide variety of tools and technologies, including Jenkins,
              GIT, Docker, Kubernetes, Maven and more, our DevOps Engineers can
              help you manage core server operations and application
              development. Our goal is to reduce the time-to-market for a
              product by automating the software delivery process.
            </p>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Our DevOps Engineers have expertise in
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    End-to-End Deployment Automation
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our Deployment automation services enable you to deploy your
                    software to testing and production environments with the
                    click of a button.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Continuous Integration and Configuration Management
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our team can seamlessly implement DevOps services and
                    solutions using continuous integration and continuous
                    deployment approach. The objective is to securely write,
                    test, as well as release high-quality code with a myriad of
                    tools like Jenkins, Bluemix, etc.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Testing and monitoring
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our team of DevOps engineers can help you configure clusters
                    and servers for improving security. Our team is skilled in
                    the monitoring of logs and the implementation of patches. We
                    have in-depth knowledge of DevOps tools including,
                    AppDynamics, Splunk, New Relic, etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    DevOps Consulting Services
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our team Identifies the best DevOps model and toolchain for
                    your business needs and optimize your existing IT structure
                    and resource usage to meet your goals faster, with fewer
                    errors and less investment.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Managed cloud Services
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our DevOps engineers can integrate your current
                    infrastructure with cloud to reduce the cost and increase
                    the scalability of the IT ecosystem. We work on the
                    different cloud platforms including, AWS, Google Cloud and
                    Azure.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Infrastructure Automation
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our tech experts can help organizations develop and automate
                    robust infrastructure to meet the growing and fast-changing
                    business needs. With DevOps infrastructure automation,
                    companies can boost cross-team collaboration, enhance
                    efficiency, fasten their IT operations and track performance
                    using monitoring tools.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">
                    Mobile Responsive Web Apps
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We utilize the latest mobile technologies to develop
                    customer-centric mobile-responsive apps that are robust and
                    scalable. Our aim is to provide an engaging user experience
                    to clients and their customers.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box8">
                  <h6 className="nativepayment4boxtxt">AI-based Web Apps</h6>
                  <p className="nativepayment4boxtxt2">
                    We develop AI-based web apps to build recommendation
                    engines, implement image recognition and enable intelligent
                    automation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">DevOps Tools We Work On</h2>
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(7).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(4).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(8).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(6).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(12).png"
                  alt="no-data"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(5).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(11).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(9).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(10).png"
                  alt="no-data"
                />
              </div>
              <div className="col-md-2">
                <img
                  className="devopstools"
                  src="/images/innerpages/Devops/devimage(3).png"
                  alt="no-data"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="Web3GameDevelopment">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="Web3GameDevelopment-sec">
                  <p className="Web3GameDevelopmentsub">Get Started</p>
                  <h2 className="Web3GameDevelopmentHead">
                    Start Making Your Web3 Ideas A Reality
                  </h2>
                  <p className="Web3GameDevelopmentxt">
                    Create futuristic, next-gen products with CST 3.0 and
                    confidently bring your ideas to life with our experienced
                    development team. Avail our super-friendly support to help
                    you manage and scale any Web2/Web3 products seamlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="Subscribenewsletter-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h2 className="SubscribenewsletterHead">
                  Subscribe to our newsletter
                </h2>
                <p className="Subscribenewslettertxt">
                  Create futuristic, next-gen products with CST 3.0 and Stay
                  up-to-date with the latest technology trends and innovations!
                  Subscribe now for exclusive insights and updates from our
                  experts!
                </p>
                <form className="subscribe-form">
                  <div className="row justify-content-center">
                    <div className=" col-md-6">
                      <div className>
                        <input
                          type="email"
                          className="form-control email-data"
                          id="email"
                          placeholder="Your email address"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary subscribe-btn"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Devops;
