import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const Web3gaming = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Helmet>
        <title>Web3 Gaming</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">CST 3.0 Web3 Game Development</h1>
                <p className="mainsubtxt">
                  We are a web3 game production firm, helmed by a team of
                  highly-skilled developers and designers, ready to help you
                  customize the next big web3 gaming experience. Our years of
                  expertise in blockchain technologies and related domains help
                  us develop web3 games of any scope, scale, and type.
                </p>
                <div className="text-center">
                  <NavLink to={"/contact-us"} className="clickbtn">
                    Get started
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Our Web3 Game Development Services
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Custom Game Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We develop custom games spanning genres like MMORPG,
                    Shooter, Action, RPGs and MOBAs. With design and
                    functionalities tailored as per your requirements and market
                    trends, we create games that strike the right chord with
                    your users.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Smart Contract Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Automate the trading of in-game assets and elevate workflow
                    pain points with our smart contract development service.
                    With expertise in all major smart contract programming
                    languages and tech stacks, we create highly adaptable smart
                    contracts for your web3 game.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Wallet Development and Integration
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Make your game web3-ready with our wallet development and
                    integration services. We create secure and robust crypto
                    wallets that enable crypto transactions and facilitate easy
                    storage and retrieval of digital assets like in-game NFTs.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">Avatar Creation</h6>
                  <p className="nativepayment4boxtxt2">
                    Deliver an immersive gaming experience to your users with
                    our avatar development service. Our artistic creativity and
                    expertise in the latest animation technologies and tools
                    allow us to create hyper-realistic avatars crafted with
                    finesse, down to the tiniest detail.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    NFT Marketplace Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Integrate your game with our NFT marketplace and offer your
                    users a ready platform to buy, sell and transfer in-game
                    digital assets. We create feature-packed NFT marketplaces
                    for games of all complexities and genres to ensure positive
                    user experiences.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">Token Development</h6>
                  <p className="nativepayment4boxtxt2">
                    Whether you need a governance token or a regular crypto
                    token for your game, we have you covered. We design and
                    develop your token on top of your chosen blockchain for
                    desired features and functionalities, so you can
                    successfully scale your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head nativepayment4head2">
              Why Choose CST 3.0 for Web3 Game Development?
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Expertise in Relevant Technology
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    We are well-versed with the latest blockchain game
                    development tools like Unity and Unreal Engine and advanced
                    technologies such as 3D reconstruction and artificial
                    intelligence. Our portfolio includes a variety of blockchain
                    gaming projects, each built to meet gamers’ specific
                    requirements
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    End-to-End Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    From conceptualizing your product to designing and
                    developing it and testing it for bugs, we take care of your
                    product through its entire lifecycle. Our maintenance and
                    upgrade services allow us to provide support for clients
                    beyond product delivery.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box6">
                  <h6 className="nativepayment4boxtxt">
                    Compatibility Across Devices
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Our blockchain games are accessible from all devices,
                    regardless of whether they are Android, iOS, or Windows. Our
                    250+ full-stack developers have deep expertise in the
                    development of complex cross-platform gaming projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Web3GameDevelopment">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="Web3GameDevelopment-sec">
                  <p className="Web3GameDevelopmentsub">Get Started</p>
                  <h2 className="Web3GameDevelopmentHead">
                    Start Making Your Web3 Ideas A Reality
                  </h2>
                  <p className="Web3GameDevelopmentxt">
                    Create futuristic, next-gen products with CST 3.0 and
                    confidently bring your ideas to life with our experienced
                    development team. Avail our super-friendly support to help
                    you manage and scale any Web2/Web3 products seamlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="Subscribenewsletter-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <h2 className="SubscribenewsletterHead">
                  Subscribe to our newsletter
                </h2>
                <p className="Subscribenewslettertxt">
                  Create futuristic, next-gen products with CST 3.0 and Stay
                  up-to-date with the latest technology trends and innovations!
                  Subscribe now for exclusive insights and updates from our
                  experts!
                </p>
                <form className="subscribe-form">
                  <div className="row justify-content-center">
                    <div className=" col-md-6">
                      <div className>
                        <input
                          type="email"
                          className="form-control email-data"
                          id="email"
                          placeholder="Your email address"
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary subscribe-btn"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Web3gaming;
