import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
const MediaPage = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    Media
                </title>
            </Helmet>
            <div className='media-content'>
                <div className="media-herosection">
                    <div className="container">
                        <div className='media-hero'>
                            <h3 className='media-title'>Technology with Accurate Solutions</h3>
                            <p className='media-subtitle'>At CST, we take pride in our commitment to excellence, innovation, and industry leadership. In this section, we highlight our exceptional team, the recognition we've received through awards and accolades, as well as our presence in the media.</p>
                        </div>

                    </div>

                </div>
                <div className="media-herosection media-herosection1">
                    <div className="container">
                        <div className='media-hero'>
                            <h3 className='media-title'>Media</h3>
                            <p className='media-subtitle'>Explore CST presence in the media. We've been featured in prominent publications, industry journals, and news outlets for our thought leadership, successful projects, and contributions to the digital landscape. Here, you'll find links to articles, interviews, and stories that highlight our expertise and impact.</p>
                        </div>

                    </div>

                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://www.outlookindia.com/business-spotlight/saiteja-annareddy-trailblazing-entrepreneur-shines-as-asia-s-transformational-leader-at-ideasfest-2023-news-292504"} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-1.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>Mr. Sai Teja Annareddy: Trailblazing Entrepreneur Shines As Asia's Transformational Leader At IDEASFEST 2023</p>
                                </NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://www.hindustantimes.com/brand-post/fintech-business-executive-sai-tej-annareddy-s-entrepreneurial-journey-101633006270923.html"} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-2.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>FinTech business executive Mr. Sai Tej Annareddy's entrepreneurial journey</p>
                                </NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://www.aninews.in/news/business/business/sustainability-app-zeroheros-secures-coveted-spot-at-brics-202320230821181554/#:~:text=By%20incentivizing%20eco%2Dfriendly%20choices,Incubator%20International%20Acceleration%20Program%202023."} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-3.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>Sustainability App Cero Hero Secures Coveted Spot at BRICS 2023
                                    </p>
                                </NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://www.mid-day.com/lifestyle/infotainment/article/blockchain-expert-sai-tej-annareddy-is-revolutionising-the-international-cryptocurrency-space-23194705"} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-4.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>Blockchain Expert Sai Tej Annareddy is Revolutionising the International Cryptocurrency Space</p>
                                </NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                            <NavLink className='mediacontent-news-inside' to={"https://theprint.in/ani-press-releases/sustainability-app-zeroheros-secures-coveted-spot-at-brics-2023/1724060/"} target='_blank'>
                                <div className='media-image-data'>
                                    <img className='media-image' src='../images/media/media-5.png' alt='no-data' />
                                </div>
                                <p className='media-para'>CST groundbreaking sustainability-focused app Cero Hero, proudly announces its selection for the upcoming BRICS Business Incubator International Acceleration Program. </p>
</NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://www.deccanherald.com/brandspot/pr-spot/how-sai-tej-annareddy-is-shaping-the-domain-of-blockchain-technology-internationally-1028630.html"} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-6.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>How Mr. Sai Tej Annareddy is shaping the Domain of Blockchain Technology Internationally</p>

                                </NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://asiaone.co.in/saitej-annareddy/"} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-7.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>Asia One Presents Greatest Brands 2023 - A Universal Platform For Cryptocurrency: Coinovy is a digital finance application that stands firm on
                                        its credibility </p>
                                </NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://wcrcleaders.com/saiteja-annareddy-orchestrating-innovation-at-the-helm-of-chakravuyha-software-technologies/"} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-8.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>In the fast-paced realm of software and blockchain solutions and  technologies, the landscape is often defined by visionaries who dare to  push boundaries and redefine industry standards. </p>
                                </NavLink>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className='mediacontent-news'>
                                <NavLink className='mediacontent-news-inside' to={"https://www.entrepreneur.com/en-ae/technology/cryptoconversations-a-chat-with-coinovy-co-founder-and/421483"} target='_blank'>
                                    <div className='media-image-data'>
                                        <img className='media-image' src='../images/media/media-9.png' alt='no-data' />
                                    </div>
                                    <p className='media-para'>Crypto Conversations: A Chat With Coinovy Co-Founder And CEO Mr. Sai Teja,  Featured in The Crypto Universe, a report published by Entrepreneur Middle East in partnership with Lucidity Insights.</p>
                                </NavLink>
                            </div>

                        </div>

                    </div>

                </div>
                <div className="media-herosection media-herosection1">
                    <div className="container">
                        <div className='media-hero'>
                            <h3 className='media-title'>Events and Talks</h3>
                            <p className='media-subtitle'>Discover the events and conferences where Mr. Saiteja Annareddy has been invited as a special guest. In this section, you can explore our contributions as distinguished speakers and guests, offering insights, expertise, and thought leadership to audiences around the world.</p>
                        </div>

                    </div>

                </div>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-1.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        At Chakravuyha Software Technologies, we're not just a tech company; we're a community of innovators and visionaries committed to shaping a brighter future.
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-2.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Today, technology has become an inevitable part of our lives and those who explore its deep secrets are endowed with great success. Saitej Annareddy is one of those passionate technologists
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-3.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Entrepreneur Middle East: Crypto Conversations with CEO Mr Sai Tej.
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-4.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        WCRCINT Transformational Leader 2023, Sri Saiteja Annareddy, CEO, of Chakravuyha Software Technologies Pvt Ltd.
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-5.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Speaker at Singapore Blockchain Meet conducted by Monetary Authority of Singapore (MAS), 2017
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-6.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Speaker at Thailand Blockchain Authority, 2017
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-7.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Speaker at International Blockchain Summit, 2019
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-8.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        World Blockchain Summit – Dubai Re-imagining The Future Of Finance With Crypto And Blockchain
                                    </p>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <div className='evenimg-inside'>
                                    <img src="../images/media/event-9.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Member of Chief Advisory Board at International Blockchain Symposium 2.0 (Malaysia), 2017
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="media-herosection media-herosection1">
                    <div className="container">
                        <div className='media-hero'>
                            <h3 className='media-title'>Awards and Felicitations</h3>
                            <p className='media-subtitle media-subtitle1'>We are honored to have been recognized by industry experts and organizations for our outstanding work in the fields of information, mobile, and web development. Some of our most notable awards include:

                            </p>
                            <p className='media-subtitle media-subtitle1'>These awards are a testament to our dedication to excellence and innovation in every project we undertake.</p>
                        </div>

                    </div>

                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="card awards-cards" >
                            <NavLink style={{ textDecoration: "none" }} to={""} target='_blank'>

                                <div className='awards-image'>
                                    <img src="../images/media/awards-1.png" className="card-img-top awaards-inside" alt="..." />
                                </div>
                                <div className="card-body awards-body">

                                    <p className="card-text awards-para">
                                        Won the International award of "Top 100 BRICS Entrepreneurs”.
                                    </p>

                                </div>
                                </NavLink>
                            </div>


                        </div>
                        <div className='col-md-4'>
                            <div className="card awards-cards" >
                            <NavLink style={{ textDecoration: "none" }} to={"   "} target='_blank'>

                                <div className='awards-image'>
                                    <img src="../images/media/awards-2.png" className="card-img-top awaards-inside" alt="..." />
                                </div>
                                <div className="card-body awards-body">

                                    <p className="card-text awards-para">
                                        SaiTeja Annareddy: Trailblazing Entrepreneur Shines As Asia's Transformational Leader At IDEASFEST 2023
                                    </p>

                                </div>
                                </NavLink>
                            </div>


                        </div>
                        <div className='col-md-4'>

                            <div className="card awards-cards" >
                                <NavLink style={{ textDecoration: "none" }} to={"https://bricsincubator.com/tpost/pb4o5g34h1-winners-of-the-brics-business-incubator"} target='_blank'>
                                <div className='awards-image'>
                                    <img src="../images/media/awards-3.png" className="card-img-top awaards-inside" alt="..." />
                                </div>
                                <div className="card-body awards-body">

                                    <p className="card-text awards-para">
                                        Mr. Sri Sai Teja Annareddy, the WINNER of the BRICS Business Incubator for Cero Hero
                                    </p>

                                </div>
                                </NavLink>
                            </div>


                        </div>

                    </div>

                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                                <NavLink style={{ textDecoration: "none" }} to={"https://wcrcleaders.com/saiteja-annareddy-orchestrating-innovation-at-the-helm-of-chakravuyha-software-technologies/"} target='_blank'>
                                <h3 className='emdiaevent-title'>Leadership Studio- WCRC Insights</h3>
                                <div className='evenimg-inside'>
                                    <img src="../images/media/awards-4.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Member of Chief Advisory Board at International Blockchain Symposium 2.0 (Malaysia), 2017
                                    </p>

                                </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                            <NavLink style={{ textDecoration: "none" }} to={""} target='_blank'>
                                <h3 className='emdiaevent-title'>Received appreciation from
                                    former IT Minister Mr. Nara
                                    Lokesh</h3>
                                <div className='evenimg-inside'>
                                    <img src="../images/media/awards-5.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        Received appreciation from IT Minister Mr. Nara Lokesh for the founding and leading Launch My ICO Pvt Ltd Received and award from World Blockchain Council, 2017
                                    </p>

                                </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                            <NavLink style={{ textDecoration: "none" }} to={""} target='_blank'>
                                <h3 className='emdiaevent-title'>AsiaOne Awards</h3>
                                <div className='evenimg-inside'>
                                    <img src="../images/media/awards-6.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        AsiaOne Magazine lauded Mr. Saitej A , a young and dynamic entrepreneur having extensive experience in blockchain, crypto currency, management, and business skills and the Chief Executive Officer of Coinovy
                                    </p>

                                </div>
                                </NavLink>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="card media-events-card" >
                            <NavLink style={{ textDecoration: "none" }} to={""} target='_blank'>

                                <h3 className='emdiaevent-title'>IDEASFEST 2023</h3>
                                <div className='evenimg-inside'>
                                    <img src="../images/media/awards-7.png" className="card-img-top mediaevents-image" alt="..." />
                                </div>

                                <div className="card-body events-body">
                                    <p className="card-text events-para">
                                        SaiTeja Annareddy: Trailblazing Entrepreneur Shines As Asia's Transformational Leader At IDEASFEST 2023
                                    </p>

                                </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default MediaPage