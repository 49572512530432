import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet";

// lottie files
import webap from "./../../../src/animations/Webap.json";
import devops from "./../../../src/animations/devops.json";
import mobile from "./../../../src/animations/app.json";
import fullstack from "./../../../src/animations/fullstack.json";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import { NavLink } from "react-router-dom";

const Web2 = () => {
  const [counts, setCounts] = useState({ count1: 0, count2: 0,count3: 0,cpunt4: 0 });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  useEffect(() => {
    let timers = {};

    function counter(id, start, end, duration) {
      let current = start;
      const range = end - start;
      const increment = end > start ? 1 : -1;
      const step = Math.abs(Math.floor(duration / range));
      
      timers[id] = setInterval(() => {
        current += increment;
        setCounts(prevCounts => ({
          ...prevCounts,
          [id]: current
        }));
        if (current === end) {
          clearInterval(timers[id]);
        }
      }, step);
    }

    counter("count1", 0, 20, 1000);
    counter("count2", 0, 10, 1000);
    counter("count3", 0, 8, 1000);
    counter("count4", 0, 7, 1000);

    return () => {
      Object.values(timers).forEach(timer => clearInterval(timer));
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Web2 Services</title>
      </Helmet>
      <div className="web2-pageData">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        <div className="leftblur-image leftblur-image2">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="container">
          <div className="section1Data-edit">
            <div className="row">
              <div className="col-md-6  p-md-0">
                <div className="leftData-edit">
                  <img
                    className="web2Image-edit"
                    src="/images/web2/web2.png"
                    alt="Web2-Solutions"
                  />
                </div>
              </div>
              <div className="col-md-6 p-md-0">
                {/* <div class="rightSide-data"> */}
                <div className="content-box">
                  <h2 className="edit-heading">Web2 Solutions</h2>
                  <p className="web2-content">
                    As a software development company specializing in developing
                    innovative solutions using blockchain, web3, ML, AI and
                    other cutting-edge technologies, CST 3.0 has experienced
                    developers dedicated to helping businesses and organizations
                    stay ahead of the curve by building purpose-specific and
                    innovative custom software and applications.
                  </p>
                  {/* <button className="getBtn-edit">Get started</button> */}
                  <NavLink
                    type="button"
                    to={"/contact-us"}
                    className="getBtn-edit"
                  >
                    Get started
                  </NavLink>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="ai-solutions">
              <div className="container">
              <div className="ai-solutions-in">
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title" id="count4">{counts.count4}</li>
                <li className="ai-solutions-inside">AI Solutions</li>
              </ul>
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title">
                <span id="count1" className="ai-solutions-inside-title">{counts.count1}</span>
                
                </li>
                <li className="ai-solutions-inside">Total Projects Delivered</li>
              </ul>
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title" id="count2">{counts.count2}</li>
                <li className="ai-solutions-inside">Full Stack Developers</li>
              </ul>
              <ul className="ai-solutions-inside">
                <li className="ai-solutions-inside-title" id="count3">{counts.count3}</li>
                <li className="ai-solutions-inside">
                  Years of Market Presence
                </li>
              </ul>
            </div>
              </div>
            </div>
          </div>
          <div className="section2-edit">
            <h3 className="web2-dream text-center">
              Make Your Web2 Dreams A Reality With CST 3.0
            </h3>
            <p className="ourSoftware-para text-center">
              Our Software Application Development Services <br />
              With 14+ years of experience, we have developed over 125+ software
              products, proving our expertise in the design and development of
              tech products. Our custom software applications meet your specific
              use case, budget, and timeline.
            </p>
            <p className="here-para text-center">
              Here’s Everything We Build In The World of Web2
            </p>
          </div>
          <div className="section3-edit">
            <div className="edit-subSection">
              <div className="row  ">
                <div className="col-md-6">
                  <div className="image-data">
                    <Lottie
                      className="webDimage-edit"
                      animationData={webap}
                      loop={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ContentSide-data">
                    <h4 className="edit-title">Web Application Development</h4>
                    <p className="editPara-content">
                      Our web application development team creates web
                      applications designed to meet specific business
                      requirements. Leveraging our hands-on experience in the
                      latest technologies like Postgre SQL, MongoDB, Angular,
                      Node.js, React and Python, we simplify complex business
                      workflows with adaptive web apps.
                    </p>
                    <NavLink
                      className="MoreBtn-edit"
                      to={"/services/web2-services/web-applicaion-developement"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-subSection">
              <div className="row row flex-column-reverse flex-md-row  ">
                <div className="col-md-6">
                  <div className="ContentSide-data">
                    <h4 className="edit-title">Devops</h4>
                    <p className="editPara-content edit-Devpara">
                      Hire our DevOps engineers to scale your business quickly
                      and get DevOps services, ranging from development,
                      automation, plugin integration to API development. Our
                      DevOps engineers work as your extended team, therefore,
                      you get a complete control over the team.
                    </p>
                    <NavLink
                      className="MoreBtn-edit"
                      to={"/services/web2-services/devops"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="image-data devops-image">
                    {/* <img
                      className="webDimage-edit "
                      src="/images/devops.png"
                      
                    /> */}
                    <Lottie
                      className="webDimage-edit"
                      animationData={devops}
                      loop={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-subSection">
              <div className="row">
                <div className="col-md-6">
                  <div className="image-data">
                    {/* <img
                      className="webDimage-edit"
                      src="/images/mobile.png"
                      
                    /> */}
                    <Lottie
                      className="webDimage-edit"
                      animationData={mobile}
                      loop={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ContentSide-data">
                    <h4 className="edit-title">Mobile Apps</h4>
                    <p className="editPara-content mobile-content">
                      Our experts handle every aspect of app development,
                      including design, testing, deployment, management and
                      maintenance for all apps, such as mobile, web, and
                      desktop.
                    </p>
                    <NavLink
                      className="MoreBtn-edit"
                      to={"/services/web2-services/mobile-app-developement"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-subSection">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-md-6">
                  <div className="ContentSide-data">
                    <h4 className="edit-title">Full stack development</h4>
                    <p className="editPara-content edit-Devpara">
                      With knowledge and experience of front-end and back-end
                      technologies, our full-stack developers can work in
                      diverse business domains. We have a team of 100+ full
                      stack developers who can develop the web app, mobile app
                      and desktop app from scratch, tailored to the needs of a
                      client.
                    </p>
                    <NavLink
                      className="MoreBtn-edit"
                      to={"/services/web2-services/fullstack"}
                    >
                      Learn more
                    </NavLink>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="image-data">
                    {/* <img
                      className="webDimage-edit"
                      src="/images/webDev.png"
                      
                    /> */}
                    <Lottie
                      className="webDimage-edit"
                      animationData={fullstack}
                      loop={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section4-edit">
          <div className="container">
            <h3 className="pDev-title text-center">
              Our Web2 Product Development Process
            </h3>
            <div className="scrollCards-section">
              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
                dir="ltr"
              >
                <SwiperSlide>
                  <div className="edit-diffItems">
                    <div className="cards-edit">
                      <h2 className="card-header">Project Mapping</h2>
                      <p className="card-para">
                        We employ a comprehensive project planning procedure to
                        concisely summarize your project objectives, priorities,
                        and potential impediments.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="edit-diffItems">
                    <div className="cards-edit">
                      <h2 className="card-header">Technical Design</h2>
                      <p className="card-para">
                        Our technical design is focused on gathering project
                        requirements. With the acquired data, we design the
                        prototype for your Web3 solution and perform its user
                        testing.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="edit-diffItems">
                    <div className="cards-edit">
                      <h2 className="card-header">Development</h2>
                      <p className="card-para">
                        At this stage, our developers perform coding and
                        programming for your Web3 project based on the approved
                        design, preparing the product for the alpha, beta and
                        release phase.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="edit-diffItems">
                    <div className="cards-edit">
                      <h2 className="card-header">Testing and Optimization</h2>
                      <p className="card-para">
                        Our team thoroughly tests various components of your
                        product, highlighting defects in the code and errors. We
                        then remove the vulnerabilities and perform
                        optimization.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="edit-diffItems">
                    <div className="cards-edit">
                      <h2 className="card-header">Project Mapping</h2>
                      <p className="card-para">
                        We employ a comprehensive project planning procedure to
                        concisely summarize your project objectives, priorities,
                        and potential impediments.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="section5-edit">
            <h3 className="why-choose text-center">
              Why Choose CST 3.0 For Web3 Development
            </h3>
            {/* <p className="whyVyuh-content text-center">
              Lorem ipsum dolor sit amet consectetur. Tristique scelerisque
              lobortis <br className="d-none d-md-block" /> tellus in habitasse.
            </p> */}
            <div className="profession-card">
              <div className="domain-cards">
                <h5 className="dev-title">Internally Trained Developers</h5>
                <p className="teams-content">
                  With a team of 250+ internally trained developers, we offer
                  end-to-end web3 development services for DeFi, metaverse,
                  gaming real-estate, e-commerce, supply chain, and more.
                </p>
              </div>
              <div className="domain-cards">
                <h5 className="dev-title">Web3 Domain Expertise</h5>
                <p className="teams-content">
                  Our developers are skilled in the web3 domain. From performing
                  wallet integration to building a full-fledged web3 solution,
                  we undertake and excel in diverse projects.
                </p>
              </div>
              <div className="domain-cards">
                <h5 className="dev-title">Wide Project Experience</h5>
                <p className="teams-content">
                  We have experience working on a wide range of web3 projects,
                  from gaming and NFTs to the metaverse. Furthermore, our team
                  is always ready to undertake innovative and challenging
                  projects.
                </p>
              </div>
            </div>
          </div>
          <div className="section6-edit">
            <p className="build-product">
              Building A Web3 Product With Us Is This Simple
            </p>
            <h5 className="listen-title">We Listen &amp; Understand</h5>
            <p className="devote-para">
              We devote time to gainisng a thorough insight into your business
              objectives and strategize accordingly via multiple consultations
              and brainstorming discussions.
            </p>
            <NavLink to={"/contact-us"} className="startCnversion-edit">
              Start a conversation
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Web2;
