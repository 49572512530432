import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { format } from "date-fns";
import { Helmet } from "react-helmet";

const Blog = () => {
  const { id } = useParams();
  const [userdata, setuserdata] = useState("");
  const [updateformate, setupdateformate] = useState("");
  const [dateformate, setdateformate] = useState("");
  const [timeformate, settimeformate] = useState("");
  const [blogData, setblogData] = useState([]);
  const [html, setHTML] = useState({ __html: "" });
  const [categorylist, setcategorylist] = useState([]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    let info = format(date, "yyyy-MM-dd");
    setdateformate(info);
  }
  function formatDate1(dateString) {
    const date = new Date(dateString);
    let info = format(date, "yyyy-MM-dd");
    setupdateformate(info);
  }
  function formatDate2(dateString) {
    const date = new Date(dateString);
    let info = format(date, "HH:mm");
    settimeformate(info);
  }
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  useEffect(() => {
    // api call
    const url = "https://blogapi.chakravuyha.com/api/blog/getBlog/" + id;
    const body = {
      _id: userdata._id,
    };
    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.get(url, body, { headers: headerTypes })
      .then((res) => {
        console.log(res.data[0]);
        setblogData(res.data[0]);
        formatDate(res.data[0].date);
        formatDate2(res.data[0].date);
        formatDate1(res.data[0].updatedOn);
        setHTML(res.data[0].description);
        setuserdata()
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    getCategory();
  }, []);
  const getCategory = (e) => {
    Axios.get("https://blogapi.chakravuyha.com/api/blog/getCategories")
      .then((res) => {
        setcategorylist(res.data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
      });
  };
  return (
    <>
      <Helmet>
        <title>Blog</title>
      </Helmet>
      {/* <div className="blogsdata-content">
        <div className="container">
          <p className="blogcategory-title">Blog categories</p>

          <div className="allblod-data">
            <ul
              className="nav nav-pills navgiations-datapills mb-3"
              id="pills-tab"
              role="tablist"
            >
              <li
                className="nav-item insidenavigation-pills"
                role="presentation"
              >
                <button
                  className="nav-link navigation-btns active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  #buycrypto
                </button>
              </li>
              <li
                className="nav-item insidenavigation-pills"
                role="presentation"
              >
                <button
                  className="nav-link navigation-btns"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  #blockchain
                </button>
              </li>
              <li
                className="nav-item insidenavigation-pills"
                role="presentation"
              >
                <button
                  className="nav-link navigation-btns"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  #cryptowallets
                </button>
              </li>
            </ul>
            <div className="tab-content insidepills-data" id="pills-tabContent">
              <div
                className="tab-pane pills-singledata fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="allblogs-dataright">
                  <div className="card blogcard-data">
                    <div className="image-data">
                      <img
                        src="./images/blogs/blog-images.png"
                        className="card-img-top image-inside"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body blogbody-data">
                      <img
                        className="img-profile"
                        src="./images/blogs/blog-profile.png"
                        alt="no-data"
                      />
                      <ul className="datetime">
                        <li className="datetime-content">
                          <i className="fa-regular fa-clock mr-2"></i>July 28
                          ‘2023
                        </li>
                        <li className="datetime-content">
                          <i className="fa-regular fa-user mr-2"></i>John
                          Halliburton
                        </li>
                      </ul>
                      <h5 className="card-title blog-title">
                        There are many variations of passages
                      </h5>
                      <p className="card-text blog-subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras ultricies ligula sed magna dictum porta. Curabitur
                        aliquet quam id dui posuere blandit.
                      </p>
                    </div>
                  </div>
                  <div className="card blogcard-data">
                    <div className="image-data">
                      <img
                        src="./images/blogs/blog-images-2.png"
                        className="card-img-top image-inside"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body blogbody-data">
                      <img
                        className="img-profile"
                        src="./images/blogs/blog-profile.png"
                        alt="no-data"
                      />
                      <ul className="datetime">
                        <li className="datetime-content">
                          <i className="fa-regular fa-clock mr-2"></i>July 28
                          ‘2023
                        </li>
                        <li className="datetime-content">
                          <i className="fa-regular fa-user mr-2"></i>John
                          Halliburton
                        </li>
                      </ul>
                      <h5 className="card-title blog-title">
                        There are many variations of passages
                      </h5>
                      <p className="card-text blog-subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras ultricies ligula sed magna dictum porta. Curabitur
                        aliquet quam id dui posuere blandit.
                      </p>
                    </div>
                  </div>
                  <div className="card blogcard-data">
                    <div className="image-data">
                      <img
                        src="./images/blogs/blog-images-3.png"
                        className="card-img-top image-inside"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body blogbody-data">
                      <img
                        className="img-profile"
                        src="./images/blogs/blog-profile.png"
                        alt="no-data"
                      />
                      <ul className="datetime">
                        <li className="datetime-content">
                          <i className="fa-regular fa-clock mr-2"></i>July 28
                          ‘2023
                        </li>
                        <li className="datetime-content">
                          <i className="fa-regular fa-user mr-2"></i>John
                          Halliburton
                        </li>
                      </ul>
                      <h5 className="card-title blog-title">
                        There are many variations of passages
                      </h5>
                      <p className="card-text blog-subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras ultricies ligula sed magna dictum porta. Curabitur
                        aliquet quam id dui posuere blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane pills-singledata fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="allblogs-dataright">
                  <div className="card blogcard-data">
                    <div className="image-data">
                      <img
                        src="./images/blogs/blog-images.png"
                        className="card-img-top image-inside"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body blogbody-data">
                      <img
                        className="img-profile"
                        src="./images/blogs/blog-profile.png"
                        alt="no-data"
                      />
                      <ul className="datetime">
                        <li className="datetime-content">
                          <i className="fa-regular fa-clock mr-2"></i>July 28
                          ‘2023
                        </li>
                        <li className="datetime-content">
                          <i className="fa-regular fa-user mr-2"></i>John
                          Halliburton
                        </li>
                      </ul>
                      <h5 className="card-title blog-title">
                        There are many variations of passages
                      </h5>
                      <p className="card-text blog-subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras ultricies ligula sed magna dictum porta. Curabitur
                        aliquet quam id dui posuere blandit.
                      </p>
                    </div>
                  </div>
                  <div className="card blogcard-data">
                    <div className="image-data">
                      <img
                        src="./images/blogs/blog-images.png"
                        className="card-img-top image-inside"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body blogbody-data">
                      <img
                        className="img-profile"
                        src="./images/blogs/blog-profile.png"
                        alt="no-data"
                      />
                      <ul className="datetime">
                        <li className="datetime-content">
                          <i className="fa-regular fa-clock mr-2"></i>July 28
                          ‘2023
                        </li>
                        <li className="datetime-content">
                          <i className="fa-regular fa-user mr-2"></i>John
                          Halliburton
                        </li>
                      </ul>
                      <h5 className="card-title blog-title">
                        There are many variations of passages
                      </h5>
                      <p className="card-text blog-subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras ultricies ligula sed magna dictum porta. Curabitur
                        aliquet quam id dui posuere blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane pills-singledata fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <div className="allblogs-dataright">
                  <div className="card blogcard-data">
                    <div className="image-data">
                      <img
                        src="./images/blogs/blog-images.png"
                        className="card-img-top image-inside"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body blogbody-data">
                      <img
                        className="img-profile"
                        src="./images/blogs/blog-profile.png"
                        alt="no-data"
                      />
                      <ul className="datetime">
                        <li className="datetime-content">
                          <i className="fa-regular fa-clock mr-2"></i>July 28
                          ‘2023
                        </li>
                        <li className="datetime-content">
                          <i className="fa-regular fa-user mr-2"></i>John
                          Halliburton
                        </li>
                      </ul>
                      <h5 className="card-title blog-title">
                        There are many variations of passages
                      </h5>
                      <p className="card-text blog-subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras ultricies ligula sed magna dictum porta. Curabitur
                        aliquet quam id dui posuere blandit.
                      </p>
                    </div>
                  </div>
                  <div className="card blogcard-data">
                    <div className="image-data">
                      <img
                        src="./images/blogs/blog-images.png"
                        className="card-img-top image-inside"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body blogbody-data">
                      <img
                        className="img-profile"
                        src="./images/blogs/blog-profile.png"
                        alt="no-data"
                      />
                      <ul className="datetime">
                        <li className="datetime-content">
                          <i className="fa-regular fa-clock mr-2"></i>July 28
                          ‘2023
                        </li>
                        <li className="datetime-content">
                          <i className="fa-regular fa-user mr-2"></i>John
                          Halliburton
                        </li>
                      </ul>
                      <h5 className="card-title blog-title">
                        There are many variations of passages
                      </h5>
                      <p className="card-text blog-subtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Cras ultricies ligula sed magna dictum porta. Curabitur
                        aliquet quam id dui posuere blandit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="blog-sub-page">
        <div className="container">
          <div className="blog-dBelow">
            <div className="row">
              <div className="col-md-3 pe-lg-4">
                <div className="left-data">
                  <div className="search-box">
                    <h2 className="search-title">Search</h2>
                    <div className="input-group inp-search">
                      <input
                        type="text"
                        className="form-control search-inside"
                        placeholder="Search Here.."
                      />
                      <img
                        src="./images/blogs/search.png"
                        alt=""
                        className="search-icn"
                      />
                      {/* <span class=" search-butn " >
                                    <i class="fas fa-search srch-icon"></i>
                                </span> */}
                    </div>
                  </div>
                  <div className="blog-catagories">
                    <h2 className="blg-title">Blog categories</h2>
                    <select
                      className="form-select d-block d-md-none"
                      aria-label="Default select example"
                    >
                      <option className="BLG-types" selected>
                        CHO
                      </option>
                      <option className="blgTypes-below" value={1}>
                        #crpt
                      </option>
                      <option className="blgTypes-below" value={2}>
                        #choice.com
                      </option>
                      <option className="blgTypes-below" value={3}>
                        #crpto.com
                      </option>
                    </select>
                    <div className="d-none d-md-block">
                      <p className="blgTypes-below">All Blogs</p>
                      {categorylist?.map((data, index) => {
                        return (
                          <p className="blgTypes-below" key={index}>
                            #{data.categories}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div class="blog-catagories">
                  <h2 class="blg-title">Blog categories</h2>
                  <p class="BLG-types">CHO</p>
                  <p class="blgTypes-below">#crpt</p>
                  <p class="blgTypes-below">#crpt</p>
                  <p class="blgTypes-below">#crpt</p>
                </div> */}
                </div>
              </div>
              <div className="col-md-9 ps-lg-3">
                <div className="B-right-Data">
                  <div className="B-cardsBelow">
                    <div className="B-card-bdy">
                      <h2 className="B-Cbody-tiitle">
                        There are many variations of passages
                      </h2>
                      <ul className="B-bdy-botom list-unstyled d-flex">
                        <li className="date-LIST">
                          {dateformate}
                          {/* {formatDate(blogData.date)} */}
                        </li>
                        <li className="date-LIST">{timeformate}</li>
                        {/* <li className="date-LIST">2 min reading</li> */}
                        <li className="date-LIST listt2">
                          Last Updated : {updateformate}
                        </li>
                      </ul>
                    </div>
                    <div className="B-cardImgs">
                      <img
                        src={`data:image/jpg;base64,${blogData.image}`}
                        alt="card"
                        className="B-edit-CardIMG"
                      />
                    </div>
                  </div>
                  <h2 className="what-nft">{blogData.title}</h2>
                  {/* <p className="nft-conttent">{blogData.description}</p> */}
                  <div
                    dangerouslySetInnerHTML={{ __html: blogData.description }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
