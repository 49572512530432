import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
// import { NavLink } from "react-router-dom";

const Nocode = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <>
      <Helmet>
        <title>No-code</title>
      </Helmet>
      <div className="noCode-pageData">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        <div className="leftblur-image leftblur-image2">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="container">
          <div className="noCode-part1">
            <div className="row">
              <div className="col-md-6 p-md-0">
                <div className="leftData-edit">
                  <img
                    className="codeImage-edit"
                    src="/images/nocode/NCpageCoding.png"
                    alt="No-Code-Products"
                  />
                </div>
              </div>
              <div className="col-md-6 p-md-0">
                {/* <div class="rightSide-data"> */}
                <div className="nProduct-box">
                  <h2 className="Product-title">No Code Products</h2>
                  <p className="discover-content">
                    Discover the power of No-code with CST 3.0 Create powerful
                    web and mobile applications and automate workflows easily
                    and quickly without any coding.
                    <br />
                    Get your <span className="mvp-edit">
                      MVP in 15 days
                    </span>{" "}
                    with us!
                  </p>
                  <NavLink to={"/contact-us"} className="startBtn-edit">
                    Get started
                  </NavLink>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="noCode-part2">
            <h3 className="why-consider text-center">
              Why Consider No-Code Tech?
            </h3>
            <p className="microsoft-content text-center">
              Microsoft anticipates that of the 500 million apps it expects to
              create in the next five years, 450 million will be designed on
              no-code, low-code platforms.
            </p>
            <div className="typesOf-card">
              <div className="noCode-cards">
                <h5 className="cardHeader-edit">Faster time-to-market</h5>
                <p className="paragrapgh-content">
                  Traditional methods require anywhere between 4 to 12 months
                  and involve constant deliberations and discussions with the
                  tech department. No-code and low-code platforms can reduce
                  this time by 90%.
                </p>
              </div>
              <div className="noCode-cards">
                <h5 className="cardHeader-edit">
                  Easier to Change and Customize
                </h5>
                <p className="paragrapgh-content">
                  You can enjoy many customization features that allow you to
                  modify the applications based on your preferences. You can
                  instantly change anything without worrying about introducing
                  bugs or glitches to the product.
                </p>
              </div>
              <div className="noCode-cards">
                <h5 className="cardHeader-edit">Cost of Ownership is Less</h5>
                <p className="paragrapgh-content">
                  No-code reduces the cost of ownership. All the application
                  data is stored on a secure cloud, reducing or eliminating
                  entirely the need to spend on servers and their maintenance.
                </p>
              </div>
              <div className="noCode-cards">
                <h5 className="cardHeader-edit">Better ROI and Low Risk</h5>
                <p className="paragrapgh-content">
                  Because the no-code is considerably cost-effective, it can
                  offer you a better return on your investments. You can save up
                  on a lot of your resources while using no-code technology
                  compared to what you would have spent on traditional methods.
                </p>
              </div>
              <div className="noCode-cards">
                <h5 className="cardHeader-edit">Easier Learning Curve</h5>
                <p className="paragrapgh-content">
                  No-code can be learned and used by anyone with limited to no
                  technical expertise or understanding. A simple drag-and-drop
                  visual editor allows you to add features of your choice
                  without having to write even a single line of code.
                </p>
              </div>
              <div className="noCode-cards">
                <h5 className="cardHeader-edit">Future-Proof Technology</h5>
                <p className="paragrapgh-content">
                  By 2024, 80% of technology products and services will be built
                  by those who are not technology professionals – Gartner, 2021.
                  If we have learned anything from the past, it is that
                  technology is moving towards convenience, and no-code
                  platforms give you just that!
                </p>
              </div>
            </div>
          </div>
          <div className="noCode-part3">
            <div className="imgData-inside">
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/airtable.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Zapier svg.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Bubble svg.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Webflow svg.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/carrd.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Mailchimp svg.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Shopify.com svg.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Jotform svg.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Framer svg.png"
                  alt="no-data"
                />
              </div>
              <div className="image-topDiv">
                <img
                  className="services-Provider"
                  src="/images/nocode/Typeform svg.png"
                  alt="no-data"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="noCode-part4">
          <div className="container">
            <h3 className="features-title text-center">
              Features of CST 3.0 No-Code Solutions
            </h3>
            <div className="scrollCards-section">
              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
                dir="rtl"
              >
                <SwiperSlide>
                  <div className="edit-diffItems">
                    <div className="slider-Cards">
                      <h2 className="card-header">Drag-and-drop interface</h2>
                      <p className="card-para">
                        It doesn’t get easier than this. Our drag-and-drop
                        interface allows anyone to pick and choose the exact
                        feature blocks they want in their software.Whether you
                        are building an app for a salon, a website for a
                        restaurant, or an eCommerce chatbot, you only need to
                        drag and drop a relevant feature block. That’s it!
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="edit-diffItems">
                    <div className="slider-Cards">
                      <h2 className="card-header">
                        Cross-platform accessibility
                      </h2>
                      <p className="card-para">
                        All no-code tools can be used on any device that is
                        connected to the internet. There is no need for any
                        specialized equipment or high-tech gadgets. Furthermore,
                        the apps or websites built on no-code platforms run on
                        all core platforms and devices.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="edit-diffItems">
                    <div className="slider-Cards">
                      <h2 className="card-header">Easy integration</h2>
                      <p className="card-para">
                        Our no-code products can help users integrate other web
                        services like Slack, Twitter, MailChimp, etc. This way,
                        users can increase the power of their software manifold
                        with just a couple of clicks.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="edit-diffItems">
                    <div className="slider-Cards">
                      <h2 className="card-header">Reusability</h2>
                      <p className="card-para">
                        The Lego-like block architecture of no-code technology
                        makes it possible to use feature blocks to create
                        fully-functional applications. These feature blocks have
                        some common functionalities that can be used and reused
                        to build more solutions quite effectively.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="edit-diffItems">
                    <div className="slider-Cards">
                      <h2 className="card-header">Reusability</h2>
                      <p className="card-para">
                        The Lego-like block architecture of no-code technology
                        makes it possible to use feature blocks to create
                        fully-functional applications. These feature blocks have
                        some common functionalities that can be used and reused
                        to build more solutions quite effectively.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="noCode-part5">
            <p className="getStarted-para">
              Get started with these easy steps 🚀
            </p>
            <div className="scrolling-words-box">
              <ul>
                <li>
                  {" "}
                  <img
                    className="handImage-edit"
                    src="/images/landing/hand.png"
                    alt="nodata"
                  />
                  Reach out
                </li>
                <li>
                  {" "}
                  <img
                    className="handImage-edit"
                    src="/images/landing/reach-icon-1.png"
                    alt="no-data"
                  />
                  Talk to us
                </li>
                <li>
                  {" "}
                  <img
                    className="handImage-edit"
                    src="/images/landing/reach-icon-3.png"
                    alt="nodata"
                  />
                  Get an estimate
                </li>
                <li>
                  {" "}
                  <img
                    className="handImage-edit"
                    src="/images/landing/reach-icon-2.png"
                    alt="no-data"
                  />
                  We will get to work
                </li>
              </ul>
            </div>

            <p className="submitRequest-para">
              Submit a request to initiate a confidential communication session
              with our knowledgeable team, arrange for a convenient appointment
              on your calendar, and connect via Zoom.
            </p>
            <NavLink to={"/contact-us"} className="InTchBTN-edit">
              Get in touch
            </NavLink>
          </div>
        </div>
        <div className="noCode-part6">
          <div className="container">
            <div className="insideData-edit">
              <h5 className="started-title">Get Started</h5>
              <h3 className="startMaking-title">
                Start Making Your Web3 Ideas A Reality
              </h3>
              <p className="create-futuristic">
                Create futuristic, next-gen products with CST 3.0 and
                confidently bring your ideas to life with our experienced
                development team. Avail our super-friendly support to help you
                manage and scale any Web2/Web3 products seamlessly.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="noCode-part7">
          <div className="container">
            <div className="subscribe-newsletter-inside">
              <p className="newsletter-title">Subscribe to our newsletter</p>
              <p className="newsletter-content">
                Stay up-to-date with the latest technology trends and
                innovations! Subscribe now for exclusive insights and updates
                from our experts!
              </p>
              <div className="row justify-content-center">
                <div className="col-md-7 pe-md-0">
                  <input
                    type="email"
                    className="your-email-address form-control"
                    placeholder="Your email address"
                  />
                </div>
                <div className="col-md-3">
                  <button className="newsletter-subscribe btn btn-primary">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Nocode;
