import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import { NavLink } from "react-router-dom";

const metaverse = () => {
  return (
    <>
      <Helmet>
        <title>Metaverse Products</title>
      </Helmet>
      <div className="navigationssections-all">
        <div className="topblur-image">
          <img
            className="righttop-blur"
            src="/images/right-top.png"
            alt="no-data"
          />
        </div>
        <div className="leftblur-image">
          <img className="left-blur" src="/images/left.png" alt="no-data" />
        </div>
        <div className="rightblur-image">
          <img className="right-blur" src="/images/right.png" alt="no-data" />
        </div>
        {/* Native Payment Solutions */}
        <div className="NativePaymentSolutions">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h1 className="mainhead">Let’s Onboard You To The Metaverse</h1>
                <p className="mainsubtxt">
                  Let us help you launch a future-ready Metaverse with our
                  technical and development experience.&nbsp; Our expertise in
                  blockchain, augmented reality, and virtual reality enables us
                  to craft comprehensive components such as 3D immersive spaces,
                  metaverse NFT marketplaces, metaverse applications, and
                  decentralized platforms.
                </p>
                <p className="mainsubtxt">
                  Step into the future of the metaverse with CST 3.0 now!
                </p>
                <div className="text-center">
                  <NavLink to={"/contact-us"} className="clickbtn">
                    Get started
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="NativePaymentsection4">
          <div className="container">
            <h2 className="nativepayment4head">
              Here’s Everything We Can Help You With
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box2">
                  <h6 className="nativepayment4boxtxt">
                    Integration Services To Your Metaverse
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Take your Metaverse to the next level with our integration
                    services. Optimize features, enhance functionalities, and
                    deliver a captivating user experience. Our service portfolio
                    includes strategic consulting and integration for APIs,
                    data, ecosystem tools, and service-oriented architecture
                    that takes the user experience of your metaverse to new
                    heights!
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box2">
                  <h6 className="nativepayment4boxtxt">
                    Metaverse 3D Space Building
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Expand the use cases of your Metaverse with our 3D space
                    design and development services. Our suite of offerings
                    includes 3D visualization, 3D modeling, and interoperability
                    – allowing the full potential of your metaverse project to
                    be unleashed!
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box2">
                  <h6 className="nativepayment4boxtxt">
                    Developing A Metaverse Gaming Space
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Tap into the future of the metaverse gaming world with us.
                    We help you build and launch gaming metaverses with super
                    engaging playability features with in-game crypto commerce
                    solutions. Our services include 3D virtual environments,
                    “play-to-earn” gaming through minting and trading NFTs, and
                    live game streaming platforms.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box2">
                  <h6 className="nativepayment4boxtxt">
                    Develop 3D Avatars For Your Metaverse
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Create an engaging metaverse experience with our 3D avatar
                    solutions. Our team leverages the latest technologies such
                    as AI, machine learning, AR, and VR to develop
                    identity-specific digital representations for your users to
                    connect and interact with one another in the metaverse.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nativepayment4box nativepayment4box2">
                  <h6 className="nativepayment4boxtxt">
                    Metaverse Web App Development
                  </h6>
                  <p className="nativepayment4boxtxt2">
                    Unlock the power of the metaverse with our innovative
                    applications and web-based solutions. Bring value to your
                    users with our metaverse web applications in business
                    communication, online education, gaming, and more. Offer
                    personalized Metaverse solutions for your customers and
                    create an unforgettable user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Vyuha 3.0 Advantage */}
        <div className="VyuhaAdvantage-section">
          <h2 className="VyuhaAdvantagehead">CST 3.0 Advantage</h2>

          <Swiper
            direction={"horizontal"}
            centeredSlides={false}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper"
            grabCursor={true}
            freeMode={true}
            spaceBetween={50}
            // slidesPerView={6}
            scrollbar={{ draggable: true }}
            dir="ltr"
          >
            <SwiperSlide>
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Hosting Solution</h6>
                <p className="Advantageslidesub">
                  Explore an entirely new realm with our high-bandwidth,
                  decentralized network of computers. Our hosting solution
                  allows your Metaverse project to reach far and wide,
                  facilitating decentralized data transmission and persistent
                  real-time connections.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">High Interoperability</h6>
                <p className="Advantageslidesub">
                  Unleash the full potential of your Metaverse project with open
                  and interoperable standards. From the text, images, audio, and
                  video to 3D scenes, items, sequences, and vectors - our
                  solution supports all types of media for your 3D applications!
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">
                  Experienced Development Clan
                </h6>
                <p className="Advantageslidesub">
                  Get the most out of your project with our open programming
                  language standards. Our support includes HTML, JavaScript,
                  WebXR, WebAssembly, WebGPU, and Shader Language - offering
                  comprehensive front-end and back-end development solutions.
                </p>
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="VyuhaAdvantageslide">
                <h6 className="Advantageslidehead">Native Smart Contracts</h6>
                <p className="Advantageslidesub">
                  Quip your Metaverse with security and efficiency with reliable
                  smart contracts. Our tailored solutions ensure transparent and
                  permissionless transactions, making it easy to carry out
                  reliable transactions safely and quickly.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/*  */}
        <div className="NativePaymentsection6">
          <div className="container">
            <h2 className="nativepayment6head">Our Recent Work</h2>
            <div className="row">
              <div className="col-md-5">
                <div className="nativepayment6main">
                  <img
                    className="nativepayment6im nativepayment6im2"
                    src="/images/innerpages/NativePaymentSolutions/starmetaverse.png"
                    alt="no-data"
                  />
                  <p className="nativepayment6txt">
                    CST has expertly brought our metaverse concept to life with an exceptional visual design and seamless user experience. Our clients are captivated by the immersive, interactive environment, and the intuitive interface has streamlined our order and client management processes. Their work has truly set a new standard for metaverse platforms.
                  </p>
                  <div className="media">
                    <img
                      src="/images/innerpages/NativePaymentSolutions/image4.png"
                      className="mr-2"
                      alt="no-data"
                    />
                    <div className="media-body">
                      <h5 className="mt-0 nativepayment6cli">
                        Ruslana Golunova
                      </h5>
                      <p className="nativepayment6des">
                        Co-founder, Starmetaverse
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="nativepayment6im2">
                  <img
                    src="/images/innerpages/NativePaymentSolutions/image22.png"
                    alt="no-data"
                  />
                </div>
                {/* <NavLink
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  href="https://starmetaverse.io/ "
                  to="_blank"
                >
                  <p className="checkitout">Check it out</p>
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default metaverse;
