import React, { useEffect, useState } from "react";
import Axios from "axios";
import toastr from "toastr";
import Lottie from "lottie-react";
import { Helmet } from "react-helmet";

// lottie files
import home from "./../../../src/animations/Home.json";
import map from "./../../../src/animations/map-1.json";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

const Landing = () => {
  const [subscribemail, setsubscribemail] = useState("");
  const [subscribemailErr, setsubscribemailErr] = useState("");
  const location = useLocation();
  const params = useParams();
  console.log("======location", location, params);

  useEffect(() => {
    const hash = location?.hash;
    if (hash) {
      const section = document.querySelector(hash);
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [location?.hash]);
  const handleChange = (event) => {
    // console.log("---->", event.target.name, event.target.value);
    if (event.target.name === "subscribemail") {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
      if (reg.test(event.target.value)) {
        setsubscribemailErr("");
      } else {
        setsubscribemailErr("please enter correct email");
      }
      setsubscribemail(event.target.value);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (subscribemail === "" || subscribemailErr !== "") {
      alert("Please fill all input fields");
      return;
    } // api call
    const url = "https://blogapi.chakravuyha.com/api/user/subscribe";
    const body = {
      email: subscribemail,
    };
    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.post(url, body, { headers: headerTypes })
      .then((res) => {
        if (res.data.code === "success") {
          setsubscribemail("");
          toastr.success(res.data.message);
        } else {
          setsubscribemail("");
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.data.message);
      });
  };
  return (
    <>
      <Helmet>
        <title>CST - Transforming Web2 to Web3 with Blockchain Solutions</title>
      </Helmet>
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-element"
        data-bs-offset={0}
        tabIndex={0}
      >
        <div className="landing-main">
          <div className="topblur-image">
            <img
              className="righttop-blur"
              src="./images/right-top.png"
              alt="no-data"
            />
          </div>
          <div className="leftblur-image">
            <img className="left-blur" src="./images/left.png" alt="no-data" />
          </div>
          <div className="rightblur-image">
            <img
              className="right-blur"
              src="./images/right.png"
              alt="no-data"
            />
          </div>
          <div className="leftblur-image leftblur-image2">
            <img className="left-blur" src="./images/left.png" alt="no-data" />
          </div>
          <div className="rightblur-image rightblur-image2">
            <img
              className="right-blur"
              src="./images/right.png"
              alt="no-data"
            />
          </div>
          <div className="landing-content">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="left-transfer">
                    <h3 className="title-data">
                      Transforming Web2 world to <span>Web3</span>
                    </h3>
                    <p className="subtitle-content">
                      Revolutionize Your Organization's Web Presence with Our
                      Scalable Web3 Products.
                    </p>
                    <Link className="getstarted-btn" to={"/contact-us"}>
                      Get started
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-transfer">
                    <div className="image-content">
                      {/* <ng-lottie className="image-data" [options]="options" (animationcreated)="animationCreated($event)" loop>
                    </ng-lottie> */}
                      <Lottie
                        className="image-data"
                        animationData={home}
                        loop={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-service-section">
            <div className="ourservices-title-sec">
              {/* <h3 className="service-title-bg">Our Services</h3> */}
              <div className="rolling-text">
                <section className="news-message">
                  <p className="service-title">Our Services</p>
                </section>
                <section className="news-message">
                  <p className="service-title">Our Services</p>
                </section>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="service-content">
                    <h3 className="service-title">Our Services</h3>
                    <p className="service-subtitle">
                      Chakravuyha Software Technologies Private Limited
                    </p>
                    <p className="service-subtitle">
                      Constructing Innovative and Unique Solutions for the New
                      Web
                    </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ourservices-data">
            <div className="container">
              <Swiper
                direction={"horizontal"}
                centeredSlides={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
                grabCursor={true}
                freeMode={true}
                spaceBetween={50}
                // slidesPerView={6}
                scrollbar={{ draggable: true }}
                dir="ltr"
              >
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-1.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        Web3 Services
                      </h5>
                      <p className="card-text service-subtitle">
                        Conceptualize and create interactive web3 solutions
                        using advanced tech like blockchain, AI, NFTs, IoT and
                        cryptography. With our experience and your drive, let’s
                        build the pioneering foundations of the internet's next
                        iteration.
                      </p>
                      <NavLink
                        to={"/services/web3-services"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-2.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        Web2 Services
                      </h5>
                      <p className="card-text service-subtitle">
                        We provide custom solutions that helps you stay ahead of
                        the competition by using innovative, purpose-driven
                        emerging technologies.&nbsp;We understand what it takes
                        to craft an engaging user experience, and have a proven
                        track.
                      </p>
                      <NavLink
                        to={"/services/web2-services"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-3.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        Hybrid Solutions
                      </h5>
                      <p className="card-text service-subtitle">
                        Our hybrid solutions are designed to provide your users
                        with the best of both worlds – the comfort of
                        traditional web browsing combined with the latest
                        technology advancements in blockchain and cryptography.
                      </p>
                      <NavLink
                        to={"/services/hybrid-solutions"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-4.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        No-Code Products
                      </h5>
                      <p className="card-text service-subtitle">
                        Technology should facilitate and ease the creation of
                        products, not be a barrier to entry.&nbsp;Join the
                        No-Code revolution now and build – applications,
                        websites, and many digital product MVPs with us in quick
                        turnarounds.
                      </p>
                      <NavLink
                        to={"/services/no-code-products"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="card card-content">
                    <img
                      src="./images/landing/service-image-5.png"
                      className="card-img-top service-image"
                      alt="no-data"
                    />
                    <div className="card-body">
                      <h5 className="card-title service-titles">
                        The CST 3.0 Advantage
                      </h5>
                      <p className="card-text service-subtitle">
                        Conceptualize and create interactive web3 solutions
                        using advanced tech like blockchain, AI, NFTs, IoT and
                        cryptography. With our experience and your drive, let’s
                        build the pioneering foundations of the internet's next
                        iteration.
                      </p>
                      <NavLink
                        to={"/contact-us"}
                        className="btn btn-primary service-btn"
                      >
                        View more <i className="fas fa-chevron-right" />
                      </NavLink>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="our-experienced" id="demo">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="technology-content">
                    <h3 className="tech-title">
                      Our Experienced Technology Experts
                    </h3>
                    {/* <p className="tech-subtitle">
                    Lorem ipsum dolor sit amet consectetur. Tristique
                    scelerisque lobortis tellus in habitasse.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-experienced">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="tech-data-content">
                    <h3 className="tech-data-title">Front-end developers</h3>
                    <p className="tech-data-subtitle">
                      We follow trends in UI/UX and industry standards while
                      infusing artistic vision into each product.
                    </p>
                    <ul className="developement-codes">
                      <li className="inside-devl">React</li>
                      <li className="inside-devl">Angular</li>
                      <li className="inside-devl">Electron</li>
                      <li className="inside-devl">Typescript</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tech-data-content">
                    <h3 className="tech-data-title">Backend developers</h3>
                    <p className="tech-data-subtitle">
                      We create scalable back-end infrastructure.
                    </p>
                    <ul className="developement-codes">
                      <li className="inside-devl">Node.js</li>
                      <li className="inside-devl">Express</li>
                      <li className="inside-devl">Next.js</li>
                      <li className="inside-devl">Nest.js</li>
                      <li className="inside-devl">PHP</li>
                      <li className="inside-devl">Symfony</li>
                      <li className="inside-devl">Laravel</li>
                      <li className="inside-devl">Lumen</li>
                      <li className="inside-devl">Yii</li>
                      <li className="inside-devl">Go</li>
                      <li className="inside-devl">Python</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tech-data-content">
                    <h3 className="tech-data-title">Mobile app development</h3>
                    <p className="tech-data-subtitle">
                      We create powerful native iOS and Android mobile apps.
                    </p>
                    <ul className="developement-codes">
                      <li className="inside-devl">Swift</li>
                      <li className="inside-devl">ObjectiveC</li>
                      <li className="inside-devl">Java</li>
                      <li className="inside-devl">Kotlin</li>
                      <li className="inside-devl">React Native</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tech-data-content">
                    <h3 className="tech-data-title">AI Developers</h3>
                    <p className="tech-data-subtitle">
                      Sophisticated machine learning solutions to power any
                      mobile or web app user experience.
                    </p>
                    <ul className="developement-codes">
                      <li className="inside-devl">Python</li>
                      <li className="inside-devl">Tensorflow</li>
                      <li className="inside-devl">Numpy</li>
                      <li className="inside-devl">Pytorch</li>
                      <li className="inside-devl">openCV</li>
                      <li className="inside-devl">dLib</li>
                      <li className="inside-devl">CUDAed</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tech-data-content">
                    <h3 className="tech-data-title">Databases</h3>
                    <p className="tech-data-subtitle">
                      We use the best popular database to scale your
                      infrastructure faster.
                    </p>
                    <ul className="developement-codes">
                      <li className="inside-devl">MySQL</li>
                      <li className="inside-devl">Mariadb</li>
                      <li className="inside-devl">Mongo</li>
                      <li className="inside-devl">Redis</li>
                      <li className="inside-devl">DynamoDB</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tech-data-content">
                    <h3 className="tech-data-title">DevOps</h3>
                    <p className="tech-data-subtitle">
                      We design products and turn your ideas into powerful
                      mobile apps.
                    </p>
                    <ul className="developement-codes">
                      <li className="inside-devl">Docker</li>
                      <li className="inside-devl">Kubernetes</li>
                      <li className="inside-devl">AWS</li>
                      <li className="inside-devl">Microsoft Azure</li>
                      <li className="inside-devl">Google Cloud</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-clients" id="testimonial">
            <div className="container">
              <h3 className="clients-title">We are featured on</h3>
              <ul className="clients-images">
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-1.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-2.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-3.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-4.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-5.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-6.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-7.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-8.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-9.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-10.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-11.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-12.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-13.png"
                    alt="no-data"
                  />
                </li>
                <li>
                  <img
                    className="image-data"
                    src="./images/landing/logos/image-14.png"
                    alt="no-data"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="reactout-content">
            <div className="container">
              <p className="reachout-top">
                Get started with these easy steps 🚀
              </p>

              {/* <div className="home-text">
              <p className="edit-animationTexts">
                <span className="Reach-title">
                  <img
                    className="handImage-edit"
                    src="/images/landing/hand.png"
                    alt="nodata"
                  />
                  Reach out
                </span>
                <span className="Reach-title">
                  <img
                    className="handImage-edit"
                    src="/images/landing/reach-icon-1.png"
                    alt="no-data"
                  />
                  Talk to us
                </span>
                <span className="Reach-title ">
                  <img
                    className="handImage-edit"
                    src="/images/landing/reach-icon-3.png"
                    alt="nodata"
                  />
                  Get an estimate
                </span>
                <span className="Reach-title ">
                  <img
                    className="handImage-edit"
                    src="/images/landing/reach-icon-2.png"
                    alt="no-data"
                  />
                  We will get to work
                </span>
              </p>
            </div> */}
              <div className="scrolling-words-box">
                <ul>
                  <li>
                    {" "}
                    <img
                      className="handImage-edit"
                      src="/images/landing/hand.png"
                      alt="nodata"
                    />
                    Reach out
                  </li>
                  <li>
                    {" "}
                    <img
                      className="handImage-edit"
                      src="/images/landing/reach-icon-1.png"
                      alt="no-data"
                    />
                    Talk to us
                  </li>
                  <li>
                    {" "}
                    <img
                      className="handImage-edit"
                      src="/images/landing/reach-icon-3.png"
                      alt="nodata"
                    />
                    Get an estimate
                  </li>
                  <li>
                    {" "}
                    <img
                      className="handImage-edit"
                      src="/images/landing/reach-icon-2.png"
                      alt="no-data"
                    />
                    We will get to work
                  </li>
                </ul>
              </div>
              <p className="reachout-para">
              Submit a request for a quote or more information and connect with our team. 
              </p>
              <NavLink className="getintouch-btn" to={"/contact-us"}>
                Get in touch
              </NavLink>
            </div>
          </div>
          <div className="growing-content">
            <div className="container">
              <div className="growing-top">
                <p className="grow-title">
                  One of The Fast Growing Blockchain Companies In Asia
                </p>
                <p className="grow-subtitle">
                Designing and building digital frameworks for startups to large enterprises. 
                </p>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="growing-inside">
                    {/* <img
                    className="map-image"
                    src="./images/landing/map.png"
                    alt="no-data"
                  /> */}
                    <Lottie
                      className="map-image"
                      animationData={map}
                      loop={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="testimonials-content" id="testimonial">
          <div className="container">
            <div className="testimonials-top">
              <p className="testimonials-title">Testimonials</p>
              <p className="testimonials-subtitle">
                Hear what our satisfied clients are saying.
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <Swiper
                  direction={"horizontal"}
                  centeredSlides={false}
                  navigation={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      // spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                  grabCursor={true}
                  freeMode={true}
                  spaceBetween={50}
                  // slidesPerView={6}
                  scrollbar={{ draggable: true }}
                  dir="ltr"
                >
                  <SwiperSlide>
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image"
                        src="./images/landing/testi-image-1.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-2.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-3.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image"
                        src="./images/landing/testi-image-1.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-2.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="testimonials-carousel">
                      <img
                        className="testimonials-image testimonials-image2"
                        src="./images/landing/testi-image-3.png"
                        alt="no-data"
                      />
                      <p className="testimonials-text">
                        We and our clients love everything about our service and
                        thanks to Diffco we have super cool website for our
                        clients and easy to use order/client management admin
                        part for us.
                      </p>
                      <div className="media">
                        <img
                          src="./images/landing/profile.png"
                          className="profile-image mr-2"
                          alt="no-data"
                        />
                        <div className="media-body">
                          <h5 className="mt-0 profile-title">
                            Ruslana Golunova
                          </h5>
                          <p className="profile-subtitle">
                            Co-founder, Starmetaverse
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div> */}
          <div className="start-making-content">
            <div className="container">
              <div className="row">
                <div className="col-md-10">
                  <div className="making-content">
                    <p className="getstart">Get Started</p>
                    <h3 className="making-title">
                      Start Making Your Web3 Ideas A Reality
                    </h3>
                    <p className="making-para">
                      Create futuristic, next-gen products with CST 3.0 and
                      confidently bring your ideas to life with our experienced
                      development team. Avail our super-friendly support to help
                      you manage and scale any Web2/Web3 products seamlessly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="subscribe-content">
            <div className="container">
              <div className="inside-subscribe">
                <h3 className="subscrib-title">Subscribe to our newsletter</h3>
                <p className="subscrib-para">
                  Stay up-to-date with the latest technology trends and
                  innovations! Subscribe now for exclusive insights and updates
                  from our experts!
                </p>
              </div>
              <form className="subscribe-form" onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                  <div className=" col-md-6">
                    <div className>
                      <input
                        type="email"
                        className="form-control email-data"
                        id="email"
                        value={subscribemail}
                        placeholder="Your email address"
                        name="subscribemail"
                        onChange={handleChange}
                      />
                      {subscribemailErr ? (
                        <p className="text-danger">{subscribemailErr}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button
                      type="submit"
                      className="btn btn-primary subscribe-btn"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
